import download from '../images/overview/download.svg';
import homebg from '../images/overview/homebg.svg';
import notificationsb from '../images/index/notificationsb.svg';
import notification from '../images/index/notification.svg';
import React from 'react';

export const changeDomain = (domain) => {
  const newDomain = 'https://backend.onebric.com';
  const url = domain.replace('https://backend.onebric', newDomain);
  return url;
};

export const overviewArr = (data) => {
  const year = new Date().getFullYear();
  const res = {
    0: [],
    1: [],
    2: [],
    3: [],
  };
  data.forEach((item) => {
    if (item.project_status !== 0 && item.project_status !== 1) {
      res[0].push(item);
    } else if (item.project_status === 0 || item.project_status === 1) {
      res[1].push(item) && res[2].push(item.invested_capital);
    }
    if (new Date(item.investment_date).getFullYear() < year - 1) {
      res[3].push(item.invested_capital);
    }
  });
  return res;
};

export const investArr = (data) => {
  const res = {};

  data?.forEach((item) => {
    const date = new Date(item.startDate || item.date).getFullYear();
    if (res[date] === undefined) {
      res[date] = [item];
    } else {
      res[date].push(item);
    }
  });
  return res;
};

export const formatList = (formattedDates) => {
  const res = [];
  Object.keys(formattedDates).forEach((key) => {
    const makeItems = [];
    formattedDates[key].forEach((item, index) => {
      makeItems.push(
        <div
          className="flex grow border-photo_ob border-b-[2px] last:border-b-0 w-[90%] m-auto items-center justify-between pb-2"
          key={index}
        >
          <span>
            {new Date(item.startDate).getDate() +
              ' ' +
              new Date(item.startDate)
                .toLocaleString('en-us', { month: 'short' })
                .toUpperCase()}
          </span>
          <div className="mx-auto flex flex-col text-sm ">
            <span>
              <strong>{item.title}</strong>
            </span>
            <div className="text-sm w-[180px] flex flex-col">
              <span>{item.type_document}</span>
              <span>{item.description}</span>
            </div>
          </div>
          <a href={changeDomain(item.url)}>
            <div className="w-[50px] h-[50px]">
              <img
                alt="download"
                src={download}
                className="object-cover w-[70px] h-[60px] !important"
              />
            </div>
          </a>
        </div>
      );
    });
    res.push(
      <React.Fragment key={key}>
        <span className="bg-bgletters_ob w-[90%] m-auto my-0 text-base text-black text-center">
          {key}
        </span>
        {makeItems}
      </React.Fragment>
    );
  });
  return res;
};

export const formatInvestor = (formattedDates) => {
  const res = [];
  Object.keys(formattedDates).forEach((key) => {
    const makeItems = [];
    formattedDates[key].forEach((item, index) => {
      makeItems.push(
        <div
          key={index}
          className="flex w-[90%] border-photo_ob border-b-[2px] last:border-b-0 my-0 items-center justify-between pb-2 mx-auto grow"
        >
          <span className="text-xs">
            {new Date(item.date).getDate() +
              ' ' +
              new Date(item.date)
                .toLocaleString('en-us', { month: 'short' })
                .toUpperCase()}
          </span>
          <div className="flex flex-col text-xs w-[180px] ml-auto px-auto pt-2">
            <span>
              <strong>{item.title}</strong>
            </span>
            <span>{item.type_document}</span>
            <span>{item.description}</span>
          </div>
          <a href={changeDomain(item.url)}>
            <img alt="download" src={download} />
          </a>
        </div>
      );
    });
    res.push(
      <React.Fragment key={'INVESTOR-DOC'}>
        <span className="bg-bgletters_ob w-[90%] m-auto my-0 text-base text-black text-center">
          {key}
        </span>
        {makeItems}
      </React.Fragment>
    );
  });
  return res;
};

export const abbreviateNumber = (value) => {
  if (value) {
    let newValue = value;

    const suffixes = ['', 'K', 'M', 'B', 'T'];

    let suffixNum = 0;

    while (newValue >= 1000) {
      newValue /= 1000;
      suffixNum++;
    }

    newValue = newValue?.toPrecision(3);

    newValue += suffixes[suffixNum];

    return newValue;
  } else {
    return '0';
  }
};

export const abbreviateDate = (date) => {
  if (date) {
    const result =
      date.getDate() +
      ' ' +
      date.toLocaleString('en-us', { month: 'short' }).toUpperCase() +
      ', ' +
      date.getFullYear();
    return result;
  } else {
    return null;
  }
};

export const abbreviateMonth = (date) => {
  if (date) {
    const result =
      date.toLocaleString('en-us', { month: 'short' }).toUpperCase() +
      ' ' +
      date.getFullYear();
    return result;
  } else {
    return null;
  }
};

export const pairsArr = (data) => {
  const res = [];
  const paresAlternados = [];
  const resPares = [];
  const resImpares = [];

  const arrayPares = data?.filter((num, index) => index % 2 === 0);
  res.push(arrayPares);

  const arrayImpares = data?.filter((num, index) => index % 2 !== 0);
  res.push(arrayImpares);

  res[0]?.forEach((item, index) => {
    resPares.push(
      <div
        key={index + 10}
        className="flex justify-between border-photo_ob border-b-[2px] last:border-b-0 py-2 w-[378px]"
      >
        <div className="w-auto m-auto">
          <img alt="home" src={homebg} className="h-[60px] w-[120px]" />
        </div>
        <span className="text-center my-auto w-[100px] text-sm">
          {new Date(item.date).getDate() +
            ' ' +
            new Date(item.date)
              .toLocaleString('en-us', { month: 'short' })
              .toUpperCase() +
            ' ' +
            new Date(item.date).getFullYear()}
        </span>
        <div className="flex flex-col self-center text-center">
          <span className="text-sm w-[140px]">{item.description}</span>
          <span className="text-xs w-[140px]">{item.type_update}</span>
        </div>{' '}
      </div>
    );
  });
  res[1]?.forEach((item, index) => {
    resImpares.push(
      <div
        key={index + 20}
        className="flex justify-between border-photo_ob border-b-[2px] last:border-b-0 py-2 w-[378px]"
      >
        <span className="text-center mx-auto my-auto w-[100px] text-sm">
          {new Date(item.date).getDate() +
            ' ' +
            new Date(item.date)
              .toLocaleString('en-us', { month: 'short' })
              .toUpperCase() +
            ' ' +
            new Date(item.date).getFullYear()}
        </span>
        <div className="flex flex-col self-center text-center">
          <span className="text-sm w-[170px]">{item.description}</span>
          <span className="text-xs w-[170px]">{item.type_update}</span>
        </div>{' '}
        <div className="w-auto m-auto">
          <img alt="home" src={homebg} className="h-[60px] w-[120px]" />
        </div>
      </div>
    );
  });

  var result = resPares
    .map((element, index) => [element, resImpares[index]])
    .flat();

  paresAlternados?.push(<div key={'UPDATES'}>{result}</div>);
  return paresAlternados;
};

export const handleClose = (data, id) => {
  const res = data?.notifications_unread?.filter(
    (element) => element?._id !== id
  );
  return res;
};

export const updateRead = (data, id) => {
  const res = data?.notifications_unread?.filter(
    (element) => element?._id === id
  );
  return res;
};

export const NotiIcon = (data, pending) => {
  if (
    (data?.length === 0 || data?.length === undefined) &&
    (pending?.length === 0 || pending?.length === undefined)
  ) {
    return (
      <>
        <img
          alt="notification"
          src={notification}
          className="mr-4 ml-4 h-[25px]"
        />
        <span className="lg:hidden">Alerts </span>
      </>
    );
  } else if (data?.length !== 0 || pending?.length !== 0) {
    return (
      <>
        <img
          alt="notification"
          src={notificationsb}
          className="mr-4 ml-4 h-[25px]"
        />
        <span className="lg:hidden">Alerts </span>
      </>
    );
  }
};

export function validatePhoneNumber(phonenumber) {
  var re = /^\+?[0-9()\s-]{6,15}$/;

  return re.test(phonenumber);
}

export function validateCountry(country) {
  var re =
    /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
  return re.test(country);
}

export const colorPalette = [
  '#275794',
  '#176791',
  '#207791',
  '#00929B',
  '#31BB9A',
  '#009B75',
  '#51556D',
  '#677286',
  '#DDF2FF',
  '#5D686D',
  '#D2AB55',
  '#FFEDCB',
  '#6EA99B',
  '#A3ACBD',
  '#C0BF74',
  '#8DA6D3',
  '#072260',
];
