import React, { useContext, useEffect, useState } from 'react';
import { NotiIcon } from '../../store/utils';
import { Link } from 'react-router-dom';
import { NotificationsContext } from '../../context/notifications/NotificationsContext';

import logo from '../../images/index/logo.svg';
import home from '../../images/index/home.svg';
import overview from '../../images/index/overview.svg';
import profile from '../../images/index/profile.svg';

const Sidebar = () => {
  const { data, pending } = useContext(NotificationsContext);
  const [trueLogo, setTrueLogo] = useState(0);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setTrueLogo(window.pageYOffset);
    });
  }, []);

  return (
    <div className="flex flex-col bg-gris w-[210px] lg:w-[180px] md:w-[150px] min-h-[90vh] fixed">
      <div className="flex flex-col mt-[70px] lg:items-center md:items-center">
        <Link
          to="/"
          className="flex items-center mr-8 lg:mr-0 font-bold font-montserrat text-white text-center pt-2 pb-2 mb-8 hover:bg-hoverbuttons rounded-r-[20px] lg:rounded-[50px]"
        >
          <img alt="home" src={home} className="mr-4 ml-4 h-[25px]" />{' '}
          <span className="lg:hidden">Home </span>
        </Link>

        <Link
          to="/overview"
          className="flex items-center font-bold lg:mr-0 mr-8 font-montserrat text-white text-center pt-2 pb-2 mb-8 hover:bg-hoverbuttons rounded-r-[20px] lg:rounded-[50px]"
        >
          <img alt="overview" src={overview} className="mr-4 ml-4 h-[25px]" />
          <span className="lg:hidden"> Overview </span>
        </Link>

        <Link
          to="/notifications"
          onClick={() => this.forceUpdate()}
          className="flex items-center font-bold mr-8 lg:mr-0 font-montserrat text-white text-center pt-2 pb-2 mb-8 hover:bg-hoverbuttons rounded-r-[20px] lg:rounded-[50px]"
        >
          {NotiIcon(data, pending)}
        </Link>

        <Link
          to="/profile"
          className="flex items-center font-bold mr-8 lg:mr-0 font-montserrat text-white text-center pt-2 pb-2 mb-8 hover:bg-hoverbuttons rounded-r-[20px] lg:rounded-[50px]"
        >
          <img alt="profile" src={profile} className="mr-4 ml-4 h-[25px]" />{' '}
          <span className="lg:hidden">Profile</span>
        </Link>
      </div>
      {trueLogo >= 50 && (
        <img
          alt="logo"
          src={logo}
          className="mt-auto mb-8 self-center w-[120px] lg:w-[100px]"
        />
      )}
    </div>
  );
};

export default Sidebar;
