import React, { useEffect, useState } from 'react';
import { changeDomain } from '../../store/utils';
import { Link } from 'react-router-dom';
import { abbreviateNumber, abbreviateDate } from '../../store/utils';
import { serviceBASE } from '../../config/axios';

import Spinner from '../../components/spinner/Spinner';
import MiniSpinner from '../../components/MiniSpinner/MiniSpinner';

const Main = () => {
  const [data, setData] = useState([]);
  const [spin, setSpin] = useState(false);
  const [sp, setSp] = useState(true);
  const [pages, setPages] = useState();
  const [page, setPage] = useState(1);

  window.onscroll = function () {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  const isLoading = () => {
    if (spin) {
      setSpin(false);
    } else {
      setSpin(true);
    }
    if (page === pages) {
      setSpin(false);
    }
  };

  const scrollToEnd = () => {
    if (page < pages) {
      setPage(page + 1);
    } else {
      return;
    }
  };

  useEffect(() => {
    const consulta = () => {
      serviceBASE
        .get(`/projects`)
        .then((response) => {
          setPages(response?.data.pages);
          setData(response?.data.docs);
          setSp(false);
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error(error);
          }
        });
    };
    consulta();
    return () => {
      setData([]);
    };
  }, []);

  useEffect(() => {
    const consulta2 = () => {
      serviceBASE
        .get(`/projects?page=${page}&size=10`)
        .then((response) => {
          isLoading();
          setData([...data, ...response?.data.docs]);
          isLoading();
        })
        .catch((error) => {
          if (error.name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error(error);
          }
        });
    };
    consulta2();
  }, [page]);

  const parseOpportunities = (opportunities) => {
    let availables = [];
    let closed = [];
    opportunities.forEach((opportunity) => {
      if (opportunity.status === 0) {
        availables.push(opportunity);
        return availables.sort(
          (a, b) => new Date(a.maturity) - new Date(b.maturity)
        );
      } else if (opportunity.status !== 0) {
        closed.push(opportunity);

        return closed.sort(
          (a, b) => new Date(b.maturity) - new Date(a.maturity)
        );
      }
    });
    return { availables, closed };
  };

  if (data) parseOpportunities(data);
  else return null;

  return (
    <>
      {sp && <Spinner />}
      <div className="mx-auto">
        <h2 className="font-montserrat text-white text-center lg:mx-auto mt-11 font-bold">
          {' '}
          Opportunities{' '}
        </h2>
        <div className="flex flex-col">
          <span className="text-white   mt-[3rem] mb-[3rem] font-montserrat ">
            Available Opportunities
          </span>

          {parseOpportunities(data)?.availables.length === 0 ? (
            <span className="flex py-2 px-11 lg:px-8 mx-auto font-montserrat items-center justify-center self-center text-center text-white rounded-[20px] bg-gris">
              There is no current investments available
            </span>
          ) : (
            parseOpportunities(data)?.availables.map((card) => (
              <Link
                to={`item/${card?._id}`}
                className="w-full mt-8"
                key={card?._id}
              >
                <div id="tarjeta" className="w-full flex flex-col lg:mx-auto">
                  <div className="flex items-center font-montserrat font-medium self-end text-white absolute rounded-tr-2xl h-[50px] w-[180px] bg-orange_ob">
                    <span className="mx-auto">OPEN</span>
                  </div>
                  <img
                    alt="photos"
                    className="object-cover bg-photo_ob h-[270px] rounded-t-2xl text-center"
                    src={
                      card?.images[0] ? changeDomain(card?.images[0].url) : null
                    }
                  />

                  <div
                    id="card-container"
                    className="bg-bgprimary_ob rounded-b-2xl"
                  >
                    <div className="flex flex-col pt-[2rem] pb-[2rem]">
                      <span className="text-orange_ob text-center font-montserrat font-light text-xs">
                        {card?.type}
                      </span>
                      <span className="text-white text-center font-montserrat font-medium text-md">
                        {card?.name}
                      </span>
                    </div>
                    <div className="flex justify-evenly text-white font-montserrat font-light text-xs pb-[2rem]">
                      <span className="w-[90px] flex flex-wrap">
                        DEADLINE
                        <p>{abbreviateDate(new Date(card?.maturity))}</p>
                      </span>
                      <span className="w-[90px] flex flex-wrap">
                        PROYECT SIZE
                        <p>
                          {card?.target !== 0
                            ? `$ ${abbreviateNumber(card?.target)}`
                            : `${card?.target}`}
                        </p>
                      </span>
                      <span className="w-[100px] flex flex-wrap">
                        TARGET NET IRR<p>{card?.target_irr} %</p>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          )}

          <span className="text-white  mt-[3rem]  font-montserrat">
            Closed Opportunities
          </span>
        </div>

        <div className="w-[815px] mx-auto flex flex-wrap justify-center mb-8 mt-8 lg:w-[603px]">
          {parseOpportunities(data)?.closed.length === 0 ? (
            <span className="flex mt-8 py-2 px-11 lg:px-8 mx-auto font-montserrat items-center justify-center self-center text-center text-white rounded-[20px] bg-gris">
              There is no closed investments available
            </span>
          ) : (
            parseOpportunities(data)?.closed.map((card) => (
              <Link
                to={`item/${card?._id}`}
                className="w-full mt-8"
                key={card?._id}
              >
                <div id="tarjeta" className="w-full flex flex-col lg:mx-auto">
                  {card.status === 1 ? (
                    <div className="flex items-center font-montserrat font-medium self-end text-white absolute rounded-tr-2xl h-[50px] w-[180px] bg-orange_ob">
                      <span className="mx-auto">PERFORMING</span>
                    </div>
                  ) : (
                    card.status === 2 && (
                      <div className="flex items-center font-montserrat font-medium text-md self-end text-center text-white absolute rounded-tr-2xl h-[50px] w-[180px] bg-orange_ob">
                        <span className="mx-auto">PAST INVESTMENT</span>
                      </div>
                    )
                  )}
                  <img
                    alt="photos"
                    className="object-cover bg-photo_ob h-[270px] rounded-t-2xl text-center"
                    src={
                      card?.images[0] ? changeDomain(card?.images[0].url) : null
                    }
                  />

                  <div
                    id="card-container"
                    className="bg-bgprimary_ob rounded-b-2xl"
                  >
                    <div className="flex flex-col pt-[2rem] pb-[2rem]">
                      <span className="text-orange_ob text-center font-montserrat font-light text-xs">
                        {card?.type}
                      </span>
                      <span className="text-white text-center font-montserrat font-medium text-md">
                        {card?.name}
                      </span>
                    </div>
                    <div className="flex justify-evenly text-white font-montserrat font-light text-xs pb-[2rem]">
                      <span className="w-[90px] flex flex-wrap">
                        DEADLINE
                        <p>{abbreviateDate(new Date(card?.deadline))}</p>
                      </span>
                      <span className="w-[90px] flex flex-wrap">
                        PROYECT SIZE
                        <p>
                          {card?.target !== 0
                            ? `$ ${abbreviateNumber(card?.target)}`
                            : `${card?.target}`}
                        </p>
                      </span>
                      <span className="w-[100px] flex flex-wrap">
                        TARGET NET IRR<p>{card?.target_irr} %</p>
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))
          )}
        </div>
        {spin && (
          <div className="text-center">
            <MiniSpinner />
          </div>
        )}
      </div>
    </>
  );
};

export default Main;
