import React, { useMemo } from "react";
import OvwMsg from "../../../../components/Messages/OvwMsg";
import { investArr, formatList } from "../../../../store/utils";

const InvestDoc = ({ data }) => {
  const docInfo = data?.documents?.statements;

  const formattedDates = investArr(docInfo);

  const formattedList = useMemo(
    () => formatList(formattedDates),
    [formattedDates]
  );

  return (
    <>
      {docInfo?.length === 0 || docInfo?.length === undefined ? (
        <OvwMsg
          message="No info for this 
        section yet."
        />
      ) : (
        formattedList
      )}
    </>
  );
};

export default InvestDoc;
