import React, { useState } from 'react';
import { serviceBASE } from '../../../config/axios';

import TextInputAc from '../../../views/modal/TextInputAc';
import Modal from '../../../components/Modal/Modal';
import Error from '../../../components/Messages/Error';

const FormInfo = ({ setIsModal, data, refetch }) => {
  const {
    first_name,
    last_name,
    address,
    country,
    phone,
    email,
    company_name,
  } = data?.personal_information || data?.entity_information;

  const [info, setInfo] = useState({
    first_name,
    last_name,
    address,
    country,
    phone,
    email,
    company_name,
  });

  const [msg, setMsg] = useState();

  const onChangeForm = (e) => {
    setInfo({
      ...info,
      [e.target.name]: e.target.value,
    });
  };

  const updateUser = async () => {
    // OB-205: Should have 'type_investor' property to update correctly
    if (!info.type_investor){
      info.type_investor = "NaturalPerson";
    }
    try {
      const response = await serviceBASE.patch(
        `/users/me/personal-information`,
        info
      );
      if (response?.status === 200) {
        setIsModal(false);
        refetch();
      }
    } catch (err) {
      setMsg(err?.response?.data?.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser();
  };

  return (
    <Modal>
      <span className="text-base">
        {first_name} {last_name}
      </span>
      <span className="text-sm block">Your Personal Information</span>
      {msg && <Error message={msg} />}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={first_name}
            type={'text'}
            name={'first_name'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="name">
            {' '}
            FIRST NAME{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={last_name}
            type={'text'}
            name={'last_name'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="last_name">
            {' '}
            LAST NAME{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={email || data?.email}
            type={'email'}
            name={'email'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="email">
            {' '}
            EMAIL{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={phone}
            type={'tel'}
            name={'phone'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="phone">
            {' '}
            PHONE{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={address}
            type={'text'}
            name={'address'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="address">
            {' '}
            ADDRESS{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <TextInputAc
            defaultValue={country}
            type={'text'}
            name={'country'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs font-light" htmlFor="country">
            {' '}
            COUNTRY{' '}
          </label>
        </div>
        <div className="flex">
          <button
            type="submit"
            className="bg-button_ob hover:bg-blue-400 w-[40%] mx-auto text-white text-sm font-regular py-2 px-4 rounded-2xl my-4"
          >
            Save
          </button>
          <button
            type="button"
            className="bg-orange_ob hover:bg-bgletters_ob w-[40%] mx-auto text-white text-sm font-regular py-2 px-4 rounded-2xl my-4"
            onClick={() => setIsModal(false)}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default FormInfo;
