import React, { useState } from 'react';

const Tabs = (props) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  return (
    <div className="mt-8">
      <div className="flex items-center justify-center">
        {props.children.map((tab, i) => (
          <button
            className="flex-1 pt-3 border-0 w-[105px] mx-2"
            onClick={() => {
              setActiveTabIndex(i);
              if (tab.props.children === 'Notifications') {
                props.setNotiSelector({
                  ...props.notiSelector,
                  notifications: true,
                  pendingItems: false,
                });
              } else {
                props.setNotiSelector({
                  ...props.notiSelector,
                  notifications: false,
                  pendingItems: true,
                });
              }
            }}
            key={i}
          >
            {tab.props.children}
          </button>
        ))}
      </div>
      <div className="flex mb-[20px]">
        <div
          className="h-[3px] mt-1 bg-orange_ob transition transform duration-100"
          style={{
            width: 100 / props.children.length + '%',
            transform: `translateX(${activeTabIndex * 100}%)`,
          }}
        />
      </div>
    </div>
  );
};

export default Tabs;
