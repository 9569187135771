import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { NotificationsContext } from '../../../context/notifications/NotificationsContext';
import { serviceBASE } from '../../../config/axios';

import logo from '../../../images/index/logo.svg';

import Spinner from '../../../components/spinner/Spinner';
import Layout from './Layout';
import Accounts from './accounts/Accounts';

const NewInvestorAcc = () => {
  const navigate = useNavigate();
  const { userData } = useContext(NotificationsContext);

  const [data, setData] = useState(null);
  const [sp, setSp] = useState(true);

  const investorsAcc = async () => {
    const response = await serviceBASE(
      `/users/me/investoracc/${userData?._id}`
    );
    if (response.status === 200) {
      setData(response.data.docs);
      setSp(false);
    } else if (response.status !== 200) {
      setSp(false);
    }
  };

  useEffect(() => {
    if (userData.length !== 0) {
      investorsAcc();
    }
  }, [userData]);

  const accStatus = (arr) => {
    if (arr?.length === 0) {
      return 'Incomplete';
    }
    if (arr !== undefined) {
      let primerElemento = arr[0]?.status;
      for (let i = 0; i < arr?.length; i++) {
        if (arr[i]?.status !== primerElemento) {
          return 'Incomplete';
        }
      }
      return primerElemento;
    }
  };

  return (
    <Layout>
      {sp && <Spinner />}
      <div className="flex flex-col justify-center items-center mt-16">
        {(userData?.personal_information?.files?.length !== 0 &&
          userData?.personal_information) ||
        (userData?.entity_information?.files?.length !== 0 &&
          userData?.entity_information) ? (
          <div className="flex w-full justify-center items-center space-x-2">
            <Accounts
              key={userData._id}
              isInvestor={false}
              userData={userData}
              accName={
                userData?.personal_information?.company_name ||
                userData?.entity_information?.company_name ||
                userData.name
              }
              type={
                userData?.personal_information?.type_investor_account !==
                  undefined ||
                userData?.entity_information?.type_investor_account !==
                  undefined
                  ? userData?.entity_information?.type_investor_account ||
                    userData?.personal_information?.type_investor_account
                  : (userData?.entity_information?.country ===
                      'United States' &&
                      userData?.entity_information?.type_investor !==
                        'NaturalPerson') ||
                    (userData?.personal_information?.country ===
                      'United States' &&
                      userData?.personal_information?.type_investor !==
                        'NaturalPerson')
                  ? 'usentity'
                  : (userData?.entity_information?.country ===
                      'United States' &&
                      userData?.entity_information?.type_investor ===
                        'NaturalPerson') ||
                    (userData?.personal_information?.country ===
                      'United States' &&
                      userData?.personal_information?.type_investor ===
                        'NaturalPerson')
                  ? 'usindividual'
                  : (userData?.entity_information?.country !==
                      'United States' &&
                      userData?.entity_information?.type_investor ===
                        'NaturalPerson') ||
                    (userData?.personal_information?.country !==
                      'United States' &&
                      userData?.personal_information?.type_investor ===
                        'NaturalPerson')
                  ? 'foreignindividual'
                  : (userData?.entity_information?.country !==
                      'United States' &&
                      userData?.entity_information?.type_investor !==
                        'NaturalPerson') ||
                    (userData?.personal_information?.country !==
                      'United States' &&
                      userData?.personal_information?.type_investor !==
                        'NaturalPerson')
                  ? 'foreignentity'
                  : null
              }
              accData={
                userData?.personal_information || userData?.entity_information
              }
            />
            <div className="w-[30%] opacity-70">
              {accStatus(
                userData?.personal_information?.files ||
                  userData?.entity_information?.files
              )}
            </div>
          </div>
        ) : null}
        {data?.length !== 0 ||
        (userData?.personal_information?.files?.length !== 0 &&
          userData?.personal_information) ||
        (userData?.entity_information?.files?.length !== 0 &&
          userData?.entity_information) ? (
          data?.map((a) => (
            <div
              className="flex w-full justify-center items-center space-x-2"
              key={a._id}
            >
              <Accounts
                userData={userData}
                accName={
                  a.company_name === ''
                    ? a.first_name + ' ' + a.last_name
                    : a.company_name
                }
                type={
                  a.type_investor_account !== undefined
                    ? a.type_investor_account
                    : a.type_investor !== 'NaturalPerson' &&
                      a.country === 'United States'
                    ? 'usentity'
                    : a.type_investor === 'NaturalPerson' &&
                      a.country === 'United States'
                    ? 'usindividual'
                    : a.type_investor !== 'NaturalPerson' &&
                      a.country !== 'United States'
                    ? 'foreignentity'
                    : a.type_investor === 'NaturalPerson' &&
                      a.country !== 'United States' &&
                      'foreignindividual'
                }
                accData={a}
                isInvestor={true}
              />
              <div className="w-[30%] opacity-70">{accStatus(a.files)}</div>
            </div>
          ))
        ) : (
          <>
            <p className="w-[50%] text-center mt-12 text-base">
              You need to add at least one Investment Account to be able to
              participate in opportunities.
              <br />
              <br />
              Please click the button below to create an Investment Account.
            </p>
          </>
        )}

        <button
          onClick={() => navigate('/profile/useraccount/register')}
          type="button"
          className={
            data?.length === 0 &&
            userData?.personal_information?.files?.length === 0
              ? 'bg-bggray_ob w-[40%] mt-12 py-1 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl'
              : 'bg-bggray_ob w-[100%] mt-12 py-1 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl'
          }
        >
          + Add New Investment Account
        </button>
        {data?.length === 0 &&
        userData?.personal_information?.files?.length === 0 ? (
          <button
            onClick={() => navigate('/')}
            className="bg-transparent w-[40%] border-2 mt-4 py-1 text-center rounded-2xl mb-8 hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Not Now
          </button>
        ) : null}

        <img src={logo} alt="logo" className="w-[180px] h-[170px] mt-2" />
      </div>
    </Layout>
  );
};

export default NewInvestorAcc;
