import React from 'react';

import logo from '../../images/index/logo.svg';

const ContainerLog = () => {
  return (
    <div className="flex flex-col mt-20">
      <img className="w-[300px] self-center" src={logo} alt="logo" />
      <span className="text-white text-center mt-12 text-base font-medium">
        You already logged in
      </span>
    </div>
  );
};

export default ContainerLog;
