import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import Spinner from '../../../components/spinner/Spinner';
import Tooltip from '../../../components/tooltip/Tooltip';
import { serviceBASE } from '../../../config/axios';
import { NotificationsContext } from '../../../context/notifications/NotificationsContext';
import Layout from './Layout';

const RegisterInvestor = () => {
  const navigate = useNavigate();

  const { userData } = useContext(NotificationsContext);

  const [data, setData] = useState(null);
  const [sp, setSp] = useState(true);
  const [hasNaturalAcc, setHasNaturalAcc] = useState(false);

  const [types, setTypes] = useState({
    usindividual: false,
    foreignindividual: false,
    usentity: false,
    foreignentity: false,
  });

  const investorsAcc = async () => {
    const response = await serviceBASE(
      `/users/me/investoracc/${userData?._id}`
    );
    if (response.status === 200) {
      setData(response.data.docs);
      setSp(false);
    } else setSp(false);
  };

  const hasInvestmentAcc = (info) => {
    if (
      info?.personal_information?.files.length !== 0 &&
      info?.personal_information?.files !== undefined
    ) {
      setHasNaturalAcc(true);
    } else return hasNaturalAcc;
  };

  useEffect(() => {
    investorsAcc();
  }, [userData]);

  useEffect(() => {
    hasInvestmentAcc(userData);
  }, [data]);

  if (types.usindividual === true)
    navigate(`/profile/useraccount/register/${Object.keys(types)[0]}`);
  else if (types.foreignindividual === true)
    navigate(`/profile/useraccount/register/${Object.keys(types)[1]}`);
  else if (types.usentity === true)
    navigate(`/profile/useraccount/register/${Object.keys(types)[2]}`);
  else if (types.foreignentity === true)
    navigate(`/profile/useraccount/register/${Object.keys(types)[3]}`);

  return (
    <Layout>
      {sp && <Spinner />}
      <div className="mt-12 flex flex-col w-full items-center">
        {hasNaturalAcc ? (
          <Tooltip tooltip={'You already have one individual account'}>
            <button
              type="button"
              className={`bg-bggray_ob opacity-20 w-[100%] mt-6 py-2 lg:w-[120%] md:w-[125%] rounded-2xl hover:drop-shadow-xl`}
            >
              US Individual Account
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            onClick={() =>
              setTypes({
                ...types,
                usindividual: true,
              })
            }
            className={`bg-bggray_ob opacity-50 hover:opacity-100 w-[30%] mt-6 py-2 lg:w-[37%] lg:ml-12 rounded-2xl  hover:drop-shadow-xl`}
          >
            US Individual Account
          </button>
        )}
        {hasNaturalAcc ? (
          <Tooltip tooltip={'You already have one individual account'}>
            <button
              type="button"
              className={`bg-bggray_ob opacity-20 w-[100%] mt-6 py-2 lg:w-[120%] md:w-[125%] rounded-2xl hover:drop-shadow-xl`}
            >
              Foreign Individual Account
            </button>
          </Tooltip>
        ) : (
          <button
            type="button"
            onClick={() =>
              setTypes({
                ...types,
                foreignindividual: true,
              })
            }
            className={`bg-bggray_ob opacity-50 hover:opacity-100 w-[30%] mt-6 py-2 lg:w-[37%] lg:ml-12 rounded-2xl  hover:drop-shadow-xl`}
          >
            Foreign Individual Account
          </button>
        )}
        <div className="flex flex-col items-center w-[100%]">
          <button
            type="button"
            onClick={() =>
              setTypes({
                ...types,
                usentity: true,
              })
            }
            className={`bg-bggray_ob opacity-50 hover:opacity-100 w-[30%] mt-6 py-2 lg:w-[37%] lg:ml-12 rounded-2xl hover:drop-shadow-xl`}
          >
            US Entity Account
          </button>
        </div>
        <div className="flex flex-col items-center w-[100%]">
          <button
            type="button"
            onClick={() =>
              setTypes({
                ...types,
                foreignentity: true,
              })
            }
            className={`bg-bggray_ob opacity-50 hover:opacity-100 w-[30%] mt-6 py-2 lg:w-[37%] lg:ml-12 rounded-2xl  hover:drop-shadow-xl`}
          >
            Foreign Entity Account
          </button>
        </div>
      </div>
    </Layout>
  );
};

export default RegisterInvestor;
