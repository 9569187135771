import React from 'react';

const Error = ({ message }) => {
  return (
    <>
      {message && (
        <div className="text-[#F80F00] text-base font-normal flex ml-6 mt-4">
          <span>*</span>
          <p className="ml-1"> {message}</p>
        </div>
      )}
    </>
  );
};

export default Error;
