import React, { useContext, useEffect, useState } from 'react';
import { InvestorAccContext } from '../../../context/investoracc/InvestorAccContext';
import { useNavigate, useParams } from 'react-router-dom';
import { serviceBASE } from '../../../config/axios';

import documents from '../../../images/documents/documents.svg';
import add_docs from '../../../images/documents/add_docs.svg';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';

import Layout from './Layout';
import Error from '../../../components/Messages/Error';
import Spinner from '../../../components/spinner/Spinner';

const InvestorDocuments = () => {
  const { type } = useParams();

  const { data, setData } = useContext(InvestorAccContext);

  const [sp, setSp] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [validate, setValidate] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    PassportOrGovernment: null,
    ProofOfAddress: null,
    BankStatement: null,
  });
  const [addDocs, setAddDocs] = useState();

  const navigate = useNavigate();

  const type_files = Object.keys(selectedFile);
  const arrFiles = data.files;

  const setInvestorAccount = async (data) => {
    try {
      const response = await serviceBASE.post('/users/me/setinvestoracc', data);
      if (response.status === 200) {
        setTimeout(() => {
          setSp(false);
          setValidate(true);
          window.location.reload(navigate('/profile/useraccount'));
        }, 1000);
      }
    } catch (err) {
      setSp(false);
      if (!err.response) {
        setValidate(false);
        return setErrMsg('No server response');
      } else if (err.response.status === 400) {
        setValidate(false);
        return setErrMsg(`${err.response.data.message}`);
      } else if (err.response.status === 401) {
        setValidate(false);
        return setErrMsg('Unauthorized');
      } else {
        setValidate(false);
        setErrMsg('You must fill all the fields');
      }
    }
  };

  useEffect(() => {
    setData({
      ...data,
      files: [
        selectedFile?.PassportOrGovernment,
        selectedFile?.ProofOfAddress,
        selectedFile?.BankStatement,
      ],
    });
  }, [selectedFile]);

  useEffect(() => {
    setAddDocs({
      ...addDocs,
      first: coordinates('add_docs_1'),
      second: coordinates('add_docs_2'),
      third: coordinates('add_docs_3'),
    });
  }, []);

  const coordinates = (element) => {
    return {
      y:
        window.scrollY +
        document.querySelector(`#${element}`)?.getBoundingClientRect().top,
      x:
        window.scrollX +
        document.querySelector(`#${element}`)?.getBoundingClientRect().left,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let i = 0; i < arrFiles.length; i++) {
      if (arrFiles[i].size > 5 * 1024 * 1024) {
        setValidate(false);
        setErrMsg('The file sizes are too large');
        return;
      }
    }

    const formData = new FormData();
    for (let i = 0; i < arrFiles.length; i++) {
      formData.append('file', arrFiles[i]);
    }
    type_files.forEach((tag) => formData.append('type_files[]', tag));
    formData.append('data', JSON.stringify(data));
    setSp(true);
    setValidate(true);
    setInvestorAccount(formData);
  };

  return (
    <Layout message={'Documentation'} setDataContext={setData}>
      {sp && <Spinner />}
      {type === 'usindividual' ||
      type === 'foreignentity' ||
      type === 'usentity' ||
      type === 'foreignindividual' ? (
        <form
          className="flex flex-col w-[70%]"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="flex justify-between">
            <div className="flex flex-col mt-12 md:w-[50%]">
              <img alt="docs" src={documents} width="40px" height="40px" />
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'foreignindividual'
                  ? 'Copy of Passport'
                  : (type === 'usentity' || type === 'foreignentity') &&
                    'Copy of Passport Authorized Individual'}
              </span>
              {/* {selectedFile.PassportOrGovernment && (
                <span className="mt-1 text-sm flex">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.PassportOrGovernment}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.PassportOrGovernment && (
                <span className="self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[40px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.first.x}px] top-[${addDocs?.first.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.first.x}px] top-[${addDocs?.first.y}px]`
                  }
                  onChange={(e) => {
                    setSelectedFile({
                      ...selectedFile,
                      PassportOrGovernment: e.target.files[0],
                    });
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[35px]"
                  id="add_docs_1"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col my-8 md:w-[50%]">
              <img alt="docs" src={documents} width="40px" height="40px" />
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'foreignindividual'
                  ? 'Proof of Address'
                  : (type === 'usentity' || type === 'foreignentity') &&
                    'Proof of Address of Authorized Individual'}
              </span>
              {/* {selectedFile.ProofOfAddress && (
                <span className="mt-1 text-sm flex">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.ProofOfAddress}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.ProofOfAddress && (
                <span className=" self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[40px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.second.x}px] top-[${addDocs?.second.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.second.x}px] top-[${addDocs?.second.y}px]`
                  }
                  onChange={(e) => {
                    setSelectedFile({
                      ...selectedFile,
                      ProofOfAddress: e.target.files[0],
                    });
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[35px]"
                  id="add_docs_2"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col mt-2">
              <img alt="docs" src={documents} width="40px" height="40px" />
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'usentity'
                  ? 'W-9 Form'
                  : type === 'foreignindividual' ? 'W-8 BEN Form' : 'W-8 BEN-E Form'}
              </span>
              {/* {selectedFile.BankStatement && (
                <span className="mt-1 text-sm flex">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.BankStatement}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.BankStatement && (
                <span className="self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[40px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.third.x}px] top-[${addDocs?.third.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.third.x}px] top-[${addDocs?.third.y}px]`
                  }
                  onChange={(e) => {
                    setSelectedFile({
                      ...selectedFile,
                      BankStatement: e.target.files[0],
                    });
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[35px]"
                  id="add_docs_3"
                />
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="bg-bggray_ob mx-auto w-[200px] py-2 rounded-2xl mt-12 hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Save Changes and Exit
          </button>
          {!validate && (
            <div className="mx-auto">
              <Error message={errMsg} />
            </div>
          )}
        </form>
      ) : (
        (type !== 'usindividual' ||
          type !== 'foreignentity' ||
          type !== 'usentity' ||
          type !== 'foreignindividual') && (
          <div className="mt-12">
            <ErrorPage />
          </div>
        )
      )}
    </Layout>
  );
};

export default InvestorDocuments;
