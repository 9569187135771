import React from 'react';
import Header from './Header';
import Sidebar from './Sidebar';

const Index = ({ children }) => {
  const token = localStorage.getItem('jwt');

  return (
    <>
      {token ? (
        <div className="flex flex-col bg-gris">
          <Header />
          <div className="flex">
            <Sidebar />
            <div className="flex flex-col bg-contenido w-full min-h-[90vh]  ml-[225px] lg:ml-[180px] md:ml-[150px]">
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col bg-gris">
          <div className="flex">
            <div className="flex flex-col bg-contenido w-full min-h-screen">
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
