import React, { Fragment } from 'react';

import OvwMsg from '../../../../components/Messages/OvwMsg';

const Detail = ({ data }) => {
  return (
    <>
      {data?.length === 0 && <OvwMsg message="No info for this section yet." />}
      {data?.coupon && data?.length !== 0 ? (
        <Fragment>
          <div className="flex flex-col items-center mb-0">
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm ">Total Loan Value</span>
              <span className="text-sm">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(data.total_loan_value)}
              </span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Invested Capital</span>
              <span className="text-sm">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(data.invested_capital)}
              </span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">% of Total Investment</span>
              <span className="text-sm">
                {data.percentage_total_investment}
              </span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Coupon Rate</span>
              <span className="text-sm">{data.coupon}</span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Coupon Frequency</span>
              <span className="text-sm">{data.frequency}</span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Estimated Net IRR</span>
              <span className="text-sm">{data.target_irr}%</span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Loan Origination Date</span>
              <span className="text-sm">
                {new Date(data?.loan_origination_date).getDate() +
                  '/' +
                  new Date(data?.loan_origination_date).getMonth() +
                  '/' +
                  new Date(data?.loan_origination_date).getFullYear()}
              </span>
            </div>
            <div className="flex  w-[90%] my-2 m-auto justify-between">
              <span className="pb-2 text-sm">Loan Maturity</span>
              <span className="text-sm">
                {new Date(data?.loan_maturity).getDate() +
                  '/' +
                  new Date(data?.loan_maturity).getMonth() +
                  '/' +
                  new Date(data?.loan_maturity).getFullYear()}
              </span>
            </div>
          </div>
        </Fragment>
      ) : !data?.coupon && data?.length !== 0 ? (
        <Fragment>
          <div className="flex flex-col items-start mb-auto">
            <div className="flex border-photo_ob border-b-[2px] w-[90%] mx-auto my-2 justify-between">
              <span className="pb-2 text-sm">Total Investment Value</span>
              <span className="text-sm">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(data.total_investment_value)}
              </span>
            </div>

            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Invested Capital</span>
              <span className="text-sm">
                {new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(data.invested_capital)}
              </span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">% of Total Invesment</span>
              <span className="text-sm">
                {data.percentage_total_investment}
              </span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Estimated Net IRR</span>
              <span className="text-sm">{data.target_irr}%</span>
            </div>
            <div className="flex border-photo_ob my-2 border-b-[2px] w-[90%] m-auto justify-between">
              <span className="pb-2 text-sm">Investment Origination Date</span>
              <span className="text-sm">
                {new Date(data?.investment_origination_date).getDate() +
                  '/' +
                  new Date(data?.investment_origination_date).getMonth() +
                  '/' +
                  new Date(data?.investment_origination_date).getFullYear()}
              </span>
            </div>
            <div className="flex w-[90%] my-2 m-auto justify-between">
              <span className="pb-2 text-sm w-[90%] m-auto">
                Expected Maturity
              </span>
              <span className="text-sm">
                {new Date(data?.project_maturity).getDate() +
                  '/' +
                  new Date(data?.project_maturity).getMonth() +
                  '/' +
                  new Date(data?.project_maturity).getFullYear()}
              </span>
            </div>
          </div>
        </Fragment>
      ) : null}
    </>
  );
};

export default Detail;
