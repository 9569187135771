import React, { useState } from 'react';
import edit from '../../../../images/documents/edit.svg';
import EditAccount from './EditAccount';

const Accounts = ({ userData, accName, type, accData, isInvestor }) => {
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <button
        className="bg-white/20 flex w-[270px] py-2 my-2 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl"
        type={'button'}
      >
        <span className="mx-auto">{accName}</span>
        <div className="w-[36px] flex items-center justify-center h-[26px] self-center">
          <img
            alt={'edit'}
            src={edit}
            className="w-[14px] cursor-pointer"
            onClick={() => {
              setShowForm(true);
            }}
          />
        </div>
      </button>
      {showForm && type !== undefined ? (
        <>
          {type === 'usentity' ? (
            <EditAccount
              isInvestor={isInvestor}
              accData={accData}
              type={type}
              setShowForm={setShowForm}
            />
          ) : type === 'usindividual' ? (
            <EditAccount
              isInvestor={isInvestor}
              userData={userData}
              accData={accData}
              type={type}
              setShowForm={setShowForm}
            />
          ) : type === 'foreignindividual' ? (
            <EditAccount
              isInvestor={isInvestor}
              userData={userData}
              accData={accData}
              type={type}
              setShowForm={setShowForm}
            />
          ) : (
            type === 'foreignentity' && (
              <EditAccount
                isInvestor={isInvestor}
                accData={accData}
                type={type}
                setShowForm={setShowForm}
              />
            )
          )}
        </>
      ) : null}
    </>
  );
};

export default Accounts;
