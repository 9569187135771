import React, { useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import EntityAcc from './entity/EntityAcc';
import IndAcc from './individual/IndAcc';

const EditAccount = ({ userData, accData, type, setShowForm, isInvestor }) => {
  const [data, setData] = useState(accData);

  const onChangeForm = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal scroll={true}>
      <div className="">
        {(type === 'usentity' || type === 'foreignentity') && (
          <EntityAcc
            isInvestor={isInvestor}
            userData={userData}
            accData={accData}
            type={type}
            onChangeForm={onChangeForm}
            data={data}
            setData={setData}
            setShowForm={setShowForm}
          />
        )}
        {(type === 'usindividual' || type === 'foreignindividual') && (
          <IndAcc
            isInvestor={isInvestor}
            userData={userData}
            accData={accData}
            type={type}
            onChangeForm={onChangeForm}
            data={data}
            setData={setData}
            setShowForm={setShowForm}
          />
        )}
      </div>
    </Modal>
  );
};

export default EditAccount;
