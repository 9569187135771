import React from 'react';
import { useEffect } from 'react';

import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';

const PlaceAutoComplete = ({ value, setValue, defaultValue }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_KEY;

  const handleSelect = async (address, placeId) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);
    const [place] = await geocodeByPlaceId(placeId);
    const { long_name: postalCode = '' } =
      place.address_components.find((c) => c.types.includes('postal_code')) ||
      {};
    const { long_name: country = '' } =
      place.address_components.find((c) => c.types.includes('country')) || {};
    const { long_name: city = '' } =
      place.address_components.find(
        (c) =>
          c.types.includes('administrative_area_level_2') ||
          c.types.includes('locality') ||
          c.types.includes('sublocality_level_1')
      ) || {};
    const { long_name: state = '' } =
      place.address_components.find((c) =>
        c.types.includes('administrative_area_level_1')
      ) || {};
    return setValue({ ...value, postalCode, city, state, country });
  };

  useEffect(() => {
    handleSelect(value?.label, value?.value.place_id);
  }, [value]);

  return (
    <>
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        apiOptions={{ language: 'en', region: 'US' }}
        minLengthAutocomplete={1}
        selectProps={{
          defaultInputValue: defaultValue ? defaultValue : null,
          value,
          onChange: setValue,
          styles: {
            input: (provided) => ({
              ...provided,
              color: 'white',
            }),
            option: (provided, state) => ({
              ...provided,
              color: 'white',
              backgroundColor: state.isFocused && '#4b5a72',
            }),
            singleValue: (provided) => ({
              ...provided,
              color: 'white',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: 'white',
            }),
            control: (provided) => ({
              ...provided,
              backgroundColor: '#374254',
              borderWidth: '0',
            }),
            indicatorSeparator: (provided) => ({
              ...provided,
              width: '0',
            }),
            menu: (provided) => ({
              ...provided,
              backgroundColor: '#374254',
            }),
            group: (provided) => ({
              ...provided,
              backgroundColor: '#4b5a72',
            }),
          },
        }}
      />
    </>
  );
};

export default PlaceAutoComplete;
