import React, { useContext, useEffect } from "react";
import { Outlet } from "react-router";
import AuthContext from "../../config/auth/authContext";
import Layout from "../../views/login/Layout";

const PrivateRoute = () => {
  const { auth, userAuth } = useContext(AuthContext);

  useEffect(() => {
    userAuth();
  }, []);

  return auth ? <Outlet /> : <Layout />;
};

export default PrivateRoute;
