import { createContext, useState } from 'react';

export const InvestorAccContext = createContext();

const InvestorAccProvider = (props) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('');
  return (
    <InvestorAccContext.Provider value={{ data, type, setData, setType }}>
      {props.children}
    </InvestorAccContext.Provider>
  );
};

export default InvestorAccProvider;
