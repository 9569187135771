import React, { useContext, useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useNavigate } from 'react-router';
import { validatePhoneNumber } from '../../../../store/utils';
import { InvestorAccContext } from '../../../../context/investoracc/InvestorAccContext';

import TextInputAc from '../../../modal/TextInputAc';
import Error from '../../../../components/Messages/Error';
import PlaceAutoComplete from '../../../../components/SearchAddres/PlaceAutoComplete';
import DefinitionModal from '../../../login/views/modal/DefinitionModal';

const UsIndividual = ({
  error,
  type,
  setError,
  personalInfo,
  onChangeForm,
  setPersonalInfo,
}) => {
  const { last_name, phone, location, first_name, email } = personalInfo;

  const { setData } = useContext(InvestorAccContext);

  const [selected, setSelected] = useState(0);
  const [secondSelect, setSecondSelect] = useState(0);
  const [googleValue, setGoogleValue] = useState(null);
  const [showModal, setModal] = useState(false);

  const onChangeRadio = (e, radio) => {
    if (radio === 'first') {
      setSelected(e);
    } else if (radio === 'second') {
      setSecondSelect(e);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (googleValue !== null) {
      setPersonalInfo({
        ...personalInfo,
        type_investor: 'NaturalPerson',
        location: {
          ...location,
          country: 'United States',
          address: googleValue?.label,
          city: googleValue?.city,
          state: googleValue?.state,
          zip_code: googleValue?.postalCode,
        },
      });
    }
  }, [googleValue]);

  useEffect(() => {
    setPersonalInfo({
      ...personalInfo,
      type_investor: 'NaturalPerson',
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      first_name === '' ||
      last_name === '' ||
      location.address === '' ||
      location.city === '' ||
      location.state === '' ||
      location.zip_code === ''
    ) {
      return setError('Please complete all the fields');
    }
    if (!validatePhoneNumber(phone)) {
      return setError('Please enter a valid phone number');
    }
    setError('');
    setData(personalInfo);

    const timer = setTimeout(() => {
      navigate(`/profile/useraccount/register/${type}/documents`);
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <>
      {showModal && <DefinitionModal setModal={setModal} />}
      <form className="w-[40%] mt-4" onSubmit={handleSubmit}>
        <div className="flex flex-col mt-2">
          <TextInputAc
            name={'first_name'}
            value={first_name}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-2" htmlFor="firstname">
            NAME
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'last_name'}
            value={last_name}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-2" htmlFor="lastname">
            LASTNAME
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'email'}
            value={email}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-2" htmlFor="firstname">
            EMAIL
          </label>
        </div>
        <div className="flex flex-col my-2">
          <PhoneInput
            country={undefined}
            inputProps={{ maxLength: 21 }}
            enableLongNumbers={true}
            regions={[
              'north-america',
              'south-america',
              'central-america',
              'carribean',
            ]}
            preferredCountries={['us', 'ar', 'mx', 'ca']}
            value={phone}
            onChange={(phone) =>
              setPersonalInfo({
                ...personalInfo,
                phone: phone,
              })
            }
            inputStyle={{
              width: '100%',
              backgroundColor: 'transparent',
              borderLeftWidth: '0px',
              borderTopWidth: '0px',
              borderRightWidth: '0px',
              borderBottomWidth: '2px',
              borderRadius: '0',
            }}
            dropdownStyle={{
              color: 'black',
            }}
            buttonStyle={{ backgroundColor: 'transparent', borderWidth: '0' }}
          />
          <label className="text-xs mt-1" htmlFor="phone">
            PHONE
          </label>
        </div>
        <div className="flex flex-col my-2">
          <div className="border-b-2 pb-1 bg-transparent">
            <PlaceAutoComplete value={googleValue} setValue={setGoogleValue} />
          </div>
          <label className="text-xs mt-1" htmlFor="address">
            ADDRESS
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'city'}
            value={location.city}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            CITY
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'state'}
            value={location.state}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            STATE
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'zip_code'}
            value={location.zip_code}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            ZIP CODE
          </label>
        </div>

        {error ? <Error message={error} /> : null}

        <div className="w-[100%] mt-6">
          <input
            type={'radio'}
            checked={selected === 1}
            onClick={(e) => {
              selected === 0
                ? onChangeRadio(1, 'first')
                : onChangeRadio(0, 'first');
              if (selected === 1) {
                setPersonalInfo({
                  ...personalInfo,
                  accredited_investor: false,
                });
              } else if (selected === 0) {
                setPersonalInfo({
                  ...personalInfo,
                  accredited_investor: true,
                });
              }
            }}
            className="mr-4"
          />
          <span className="text-base font-base">
            I certify I am an “Accredited Investor” defined by the U.S.
            Securities and Exchange Commission.
          </span>{' '}
          <button
            className="text-sm font-base text-[#2881FB]"
            type={'button'}
            onClick={() => setModal(true)}
          >
            (Read definition here)
          </button>
        </div>

        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="bg-bggray_ob w-[25%] py-1 rounded-2xl my-8 hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};
export default UsIndividual;
