import React, { useContext } from 'react';
import { useNavigate } from 'react-router';
import { InvestorAccContext } from '../../../context/investoracc/InvestorAccContext';

import back from '../../../images/index/back-button.svg';

const Layout = ({ children, message }) => {
  const navigate = useNavigate();

  const { type } = useContext(InvestorAccContext);

  return (
    <div className="flex flex-col items-center py-4 px-4 text-white font-montserrat">
      <div className="flex w-[80%] justify-center mt-8">
        <img
          className="text-2xl self-start relative right-[30%] cursor-pointer"
          onClick={() => {
            navigate(
              message === 'Individual Investment Account Information' ||
                message === 'Entity Investment Account Information'
                ? '/profile/useraccount/register'
                : message === 'Documentation'
                ? `/profile/useraccount/register/${type}`
                : message === undefined && '/profile'
            );
          }}
          src={back}
          alt="back"
        />
        <div className="flex flex-col">
          <h1 className="self-center text-lg mr-4">Investment Account</h1>
          {message ? (
            <span className="text-sm text-[#FFFFFF4D]">{message}</span>
          ) : null}
        </div>
      </div>
      {children}
    </div>
  );
};

export default Layout;
