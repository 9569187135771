import React, { useState } from 'react';

import BarChart from './bar/BarChart';

export default function StChartMonthly({ sum, data, loaded }) {
  const [quarter] = useState(false);

  const year = new Date().getFullYear();

  const getProjectsByType = (type, dataProject) => {
    let project = [];
    project = dataProject?.filter(
      (investment) => investment?.project_type === type
    );
    return project;
  };

  const portfolioLoans = getProjectsByType('PORTFOLIO LOAN', data);
  const equityLoans = getProjectsByType('EQUITY INVESTMENT', data);

  const totalCapital = (project, date, q) => {
    const fecha = new Date();
    let first = {
      amount: 0,
      date: new Date(year, fecha.getMonth() - 3, 0),
    };

    let second = {
      amount: 0,
      date: new Date(year, fecha.getMonth() - 2, 0),
    };
    let third = {
      amount: 0,
      date: new Date(year, fecha.getMonth() - 1, 0),
    };
    let fourth = {
      amount: 0,
      date: new Date(year, fecha.getMonth(), 0),
    };

    let total = [];

    project.forEach((item) => {
      const {
        invested_capital: investedCapital,
        project_maturity: projectMaturity,
      } = item;

      if (
        new Date(date) < new Date(projectMaturity) &&
        first.date < new Date(projectMaturity)
      ) {
        first.amount += investedCapital;
      }
      if (
        new Date(date) < new Date(projectMaturity) &&
        second.date < new Date(projectMaturity)
      ) {
        second.amount += investedCapital;
      }
      if (
        new Date(date) < new Date(projectMaturity) &&
        third.date < new Date(projectMaturity)
      ) {
        third.amount += investedCapital;
      }
      if (
        new Date(date) < new Date(projectMaturity) &&
        fourth.date < new Date(projectMaturity)
      ) {
        fourth.amount += investedCapital;
      }
    });
    total.push(first.amount, second.amount, third.amount, fourth.amount);
    return total;
  };

  let equity_inv = totalCapital(
    equityLoans,
    new Date(year, new Date().getMonth() - 3, 0),
    quarter
  );

  let portfolio_loan = totalCapital(
    portfolioLoans,
    new Date(year, new Date().getMonth() - 3, 0),
    quarter
  );

  const xData = () => {
    let actualMonth = new Date().getMonth();
    let lastMonth = actualMonth - 3;
    let months = [];

    for (lastMonth; lastMonth <= actualMonth; lastMonth++) {
      months.push(
        new Date(year, lastMonth, 0).toLocaleString('en-us', {
          month: 'short',
          year: 'numeric',
        })
      );
    }
    return months;
  };

  return (
    <div className="flex flex-col">
      <div className="ml-2 w-[380px] lg:w-[300px] ">
        <BarChart
          sum={sum}
          data1={portfolio_loan}
          data2={equity_inv}
          loaded={loaded}
          monthList={xData()}
        />
      </div>
    </div>
  );
}
