import React, { useContext, useEffect, useState } from 'react';
import { serviceAUTH, serviceBASE } from '../../config/axios';
import { useNavigate } from 'react-router';

import logo from '../../images/index/logo.svg';
import logout from '../../images/signup/logout.svg';
import Spinner from '../../components/spinner/Spinner';
import FormInfo from './forms/FormInfo.js';
import FormPw from './forms/FormPw';
import AuthContext from '../../config/auth/authContext';

const Profile = () => {
  const { removeToken } = useContext(AuthContext);

  let navigate = useNavigate();

  const [data, setData] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [isModalpw, setIsModalpw] = useState(false);
  const [validate, setValidate] = useState(false);
  const [sp, setSp] = useState(true);

  const body = document.querySelector('body');

  const { _id } = data;

  const refetch = async () => {
    const response = await serviceBASE.get(`/users/me`);
    setData(response.data);
  };

  const logOut = async () => {
    setSp(true);
    await serviceAUTH.post('/logout', {
      _id: _id,
    });
    setSp(false);
  };

  useEffect(() => {
    const consulta = async () => {
      const response = await serviceBASE.get(`/users/me`);
      setData(response.data);
      setSp(false);
    };
    consulta();
  }, []);

  if (isModal || isModalpw) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'auto';
  }

  return (
    <>
      {(sp || validate) && <Spinner />}
      <div className="flex flex-col font-montserrat min-h-[90vh] text-white">
        <h3 className="text-center my-8 font-bold">Profile</h3>
        <div className="mx-auto rounded-2xl bg-bgprimary_ob w-[700px] lg:w-[603px] md:w-[560px] flex flex-col pb-4 mb-8">
          <div className="flex mx-auto w-[90%] pb-4 pt-11 flex-col border-photo_ob border-b-[2px]">
            <span className="text-sm ml-4 font-medium">
              {data?.personal_information || data?.entity_information
                ? (data?.personal_information?.first_name ||
                    data?.entity_information?.first_name) +
                  ' ' +
                  (data?.personal_information?.last_name ||
                    data?.entity_information?.last_name)
                : 'Account Name'}
            </span>
            <button
              className="text-xs ml-4 cursor-pointer w-fit"
              onClick={() => setIsModal(true)}
            >
              {' '}
              Edit your Personal Information{' '}
            </button>
            {isModal && (
              <FormInfo
                setIsModal={setIsModal}
                data={data}
                _id={_id}
                refetch={refetch}
              />
            )}
          </div>
          <div className="flex mx-auto w-[90%] pb-4 pt-8 flex-col border-photo_ob border-b-[2px]">
            <span className="text-sm ml-4 font-medium">Privacy & Security</span>
            <button
              className="text-xs ml-4 cursor-pointer w-fit"
              onClick={() => setIsModalpw(true)}
            >
              {' '}
              Change your password{' '}
            </button>
            {isModalpw && <FormPw setIsModalpw={setIsModalpw} />}
          </div>

          <div className="flex mx-auto w-[90%] pb-4 pt-8 flex-col border-photo_ob border-b-[2px]">
            <span className="text-sm ml-4 font-medium">
              Investment Accounts
            </span>
            <button
              className="text-xs ml-4 cursor-pointer w-fit"
              type="button"
              onClick={() => navigate('/profile/useraccount')}
            >
              {' '}
              Manage your Investment Accounts{' '}
            </button>
          </div>

          <div className="flex my-8 justify-evenly">
            <button
              className="font-semibold text-2xl flex"
              onClick={() => {
                setValidate(true);
                logOut();
                setTimeout(() => {
                  removeToken();
                  window.location.reload(navigate('/'));
                }, 1500);
              }}
            >
              Logout
              <img
                alt="logout"
                src={logout}
                className="w-[22px] h-[22px] ml-2 mt-1"
              />
            </button>{' '}
            <img alt={logo} src={logo} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
