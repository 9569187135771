import React, { useMemo } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  ArcElement,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

const BarChart = ({ sum, data1, data2, loaded, monthList }) => {
  const options = {
    fill: true,
    animation: true,
    responsive: true,
    hoverOffset: 4,
    borderRadius: {
      topLeft: 30,
      topRight: 30,
    },
    scales: {
      y: {
        min: 0,
        max: sum,
      },
    },
    plugins: {
      tooltip: {
        enabled: true,
        boxWidth: 5,
        usePointStyle: true,
        pointStyle: 'rectRounded',
        boxPadding: 2,
        bodyFont: {
          size: 13,
          family: 'montserrat',
        },
        titleFont: {
          size: 13,
          family: 'montserrat',
        },
        titleAlign: 'center',
      },
      legend: {
        display: false,
        labels: {
          color: 'white',
          boxWidth: 5,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          font: {
            size: 14,
            family: 'montserrat',
          },
        },
      },
    },
  };

  const dataChart = useMemo(() => {
    const labels = monthList;
    return {
      datasets: [
        {
          label: 'Loan',
          data: data1,
          backgroundColor: '#1A2745',
          borderWidth: 1,
        },
        {
          label: 'Equity',
          data: data2,
          backgroundColor: 'rgb(224, 168, 69, 1)',
          borderWidth: 1,
        },
      ],
      labels,
    };
  }, [loaded]);

  return <Bar data={dataChart} options={options} />;
};

export default BarChart;
