import React from 'react';
import { Link } from 'react-router-dom';

import ContainerLog from '../../../components/utils/ContainerLog';

import logo from '../../../images/index/logo.svg';

const Signup = () => {
  const token = localStorage.getItem('jwt');

  return (
    <>
      {!token ? (
        <div className="font-montserrat flex flex-col self-center">
          <img className="w-[300px] mt-12 self-center" src={logo} alt="logo" />
          <h3 className="text-center text-white mt-[100px] font-bold text-2xl">
            Sign Up
          </h3>
          <div className=" flex flex-col w-[400px]">
            {/* <FbLogin /> */}
            {/* <div className="cursor-pointer flex justify-center bg-white hover:bg-gray-300 text-black py-2 px-4 rounded-2xl my-6">
              <img alt="applelogo" src={logo_apple} />
              <button className="ml-2 text-base font-medium">
                Sign up with Apple
              </button>
            </div> */}
            <Link
              to={'/signup/register'}
              className="cursor-pointer mt-12 font-medium bg-bgregister_ob text-base text-center hover:bg-gray-500 hover:drop-shadow-xl text-white py-2 px-4 rounded-2xl"
            >
              Register with email
            </Link>

            <span className="text-white mt-11 font-medium mx-auto">
              Already have an account?{' '}
              <Link
                to={'/signup/login'}
                className="text-orange_ob cursor-pointer"
              >
                Log In
              </Link>
            </span>
          </div>
        </div>
      ) : (
        <ContainerLog />
      )}
    </>
  );
};

export default Signup;
