import React from 'react';
import BtnSlider from '../../components/Carousel/BtnSlider';
import ModalCarousel from '../../components/Carousel/ModalCarousel';
import { changeDomain } from '../../store/utils';

const Photos = ({ dataSlider, setBigPhoto, slideIndex, setSlideIndex }) => {
  const nextSlide = () => {
    if (slideIndex !== dataSlider?.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider?.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider?.length);
    }
  };

  return (
    <ModalCarousel>
      <div className="container-slider mb-[10rem] mr-[5rem]">
        {dataSlider?.map((obj, index) => {
          return (
            <div
              key={obj._id}
              className={
                slideIndex === index + 1
                  ? 'slide active-anim flex flex-col'
                  : 'slide flex flex-col'
              }
            >
              <span
                className="text-white font-bold self-end mr-4 text-2xl cursor-pointer"
                onClick={() => setBigPhoto(false)}
              >
                X
              </span>
              <img
                alt="photos"
                className={`object-cover h-[468px] w-[915px] rounded-2xl text-center lg:w-[750px] md:w-[650px]`}
                src={obj ? changeDomain(obj.url) : null}
              />

              {dataSlider?.length <= 1 ? null : (
                <>
                  <BtnSlider moveSlide={nextSlide} direction={'next'} />
                  <BtnSlider moveSlide={prevSlide} direction={'prev'} />
                </>
              )}
            </div>
          );
        })}
      </div>
    </ModalCarousel>
  );
};

export default Photos;
