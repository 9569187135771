import React, { useContext, useState } from "react";

import Error from "../../../../components/Messages/Error";
import Message from "../../../../components/Messages/Message";
import AuthContext from "../../../../config/auth/authContext";
import TextInputAc from "../../../modal/TextInputAc";

const RecoverForm = ({ setRecovPw }) => {
  const [newEmail, setEmail] = useState({ email: "" });
  const { resetPassword, validate, setValidate, errMsg, setErrMsg } =
    useContext(AuthContext);

  const { email } = newEmail;

  const onChangeForm = (e) => {
    setEmail({
      ...newEmail,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword(newEmail.email);
  };

  return (
    <form onSubmit={handleSubmit}>
      <button
        type="button"
        className="text-xl"
        onClick={() => {
          setRecovPw(false);
          setValidate(false);
          setErrMsg("");
        }}
      >
        &larr;
      </button>
      <div className="flex flex-col mt-6">
        <TextInputAc
          name={"email"}
          value={email}
          type={"email"}
          onChangeForm={onChangeForm}
        />
        <label className="text-xs mt-1" htmlFor="email">
          EMAIL
        </label>
      </div>
      {errMsg && <Error message={errMsg} />}
      {validate && (
        <Message succesfull="Password reseted succesfully, check your email" />
      )}
      <div className="flex justify-center">
        <button
          type="submit"
          className="bg-bgnewgray_ob w-[45%] py-1 rounded-2xl my-8"
        >
          Recover Password
        </button>
      </div>
    </form>
  );
};

export default RecoverForm;
