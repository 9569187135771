import React, { Fragment } from "react";

import logo from "../../images/index/logo.svg";

const Header = () => {
  return (
    <Fragment>
      <nav className="flex items-center h-[10vh] bg-gris">
        {" "}
        <img
          alt="logo"
          src={logo}
          className="ml-[30px] w-[170px] lg:w-[130px] md:w-[110px]"
        />
      </nav>
    </Fragment>
  );
};

export default Header;
