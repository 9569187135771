import React, { useContext } from 'react';
import { handleClose, updateRead } from '../../../store/utils';
import { serviceBASE } from '../../../config/axios';
import { NotificationsContext } from '../../../context/notifications/NotificationsContext';
import { useNavigate } from 'react-router';

const NotificationView = ({
  miscData,
  title,
  date,
  body,
  link,
  id,
  read,
  type,
  pending,
}) => {
  const { refetch, data } = useContext(NotificationsContext);

  let navigate = useNavigate();

  const readNotification = async () => {
    try {
      const response = await serviceBASE.patch(
        `/users/me/notifications/${id}`,
        {
          data: JSON.stringify({
            notifications_unread: handleClose(data, id),
            notifications_read: updateRead(data, id),
          }),
        },
        {
          headers: {
            'Content-type': `application/json`,
          },
        }
      );
      if (response.status === 200) {
        refetch();
      }
    } catch (err) {
      console.log('There has been a problem with your fetch operation: ' + err);
    }
  };

  return (
    <div
      className={
        read
          ? `opacity-40 flex mx-auto w-[90%] py-4 my-4 first:pt-8  border-photo_ob border-b-[2px] last:border-b-0 ${
              link !== undefined && pending
                ? null
                : link === undefined && pending
                ? null
                : link === undefined &&
                  pending === undefined &&
                  'cursor-pointer'
            }`
          : 'flex mx-auto w-[90%] py-4  my-4 first:pt-8 border-photo_ob border-b-[2px] last:border-b-0 cursor-pointer'
      }
    >
      <div
        className="flex flex-col w-[100%]"
        onClick={() => {
          if (read === undefined) {
            if (Object.keys(miscData).length !== 0 && miscData.investmentId) {
              readNotification();
              navigate(`/overview/holding/${miscData.investmentId}`, {
                state: { id: id, miscData },
              });
            } else if (
              Object.keys(miscData).length !== 0 &&
              miscData.projectId
            ) {
              readNotification();
              navigate(`/item/${miscData.projectId}`, {
                state: { id: id, miscData },
              });
            } else if (
              Object.keys(miscData).length === 0 &&
              type !== 'Pending Item'
            ) {
              readNotification();
              setTimeout(() => {
                navigate(`/profile`);
              }, 500);
            } else if (
              Object.keys(miscData).length === 0 &&
              type === 'Pending Item'
            ) {
              readNotification();
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }
          } else if (read === true) {
            if (Object.keys(miscData).length !== 0 && miscData.investmentId)
              navigate(`/overview/holding/${miscData.investmentId}`, {
                state: { id: id, miscData },
              });
            else if (Object.keys(miscData).length !== 0 && miscData.projectId) {
              navigate(`/item/${miscData.projectId}`, {
                state: { id: id, miscData },
              });
            } else if (
              Object.keys(miscData).length === 0 &&
              type !== 'Pending Item'
            ) {
              navigate(`/profile`);
            } else if (
              Object.keys(miscData).length === 0 &&
              type === 'Pending Item'
            ) {
            }
          }
        }}
      >
        <span className="text-sm ml-4 font-medium flex justify-between">
          {body}
        </span>
        <span className="text-sm ml-4 mt-1 font-medium">
          {' '}
          {new Date(date).toLocaleString('en-us', { month: 'long' }) +
            ' ' +
            new Date(date).getDate() +
            ', ' +
            new Date(date).getFullYear()}
        </span>
        <span className="text-xs ml-4 mt-2">{title}</span>
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-tiny ml-4 mt-2 w-fit underline text-[#2881FB] hover:text-[#1b7aff94] visited:text-purple-600"
            onClick={() => readNotification()}
          >
            {link}
          </a>
        )}
      </div>
    </div>
  );
};
export default NotificationView;
