import React, { Fragment } from 'react';
import './style/InputStyle.css';

const TextInputAc = ({
  type,
  name,
  defaultValue,
  onChangeForm,
  value,
  readonly,
}) => {
  return (
    <Fragment>
      {readonly ? (
        <input
          defaultValue={defaultValue}
          type={type}
          name={name}
          className="outline-[0px] bg-transparent border-photo_ob border-b-[2px] placeholder:text-base placeholder:font-light placeholder:text-white"
          onChange={onChangeForm}
          value={value}
          autoComplete="off"
          readOnly
        />
      ) : (
        <input
          defaultValue={defaultValue}
          type={type}
          name={name}
          className="outline-[0px] bg-transparent border-photo_ob border-b-[2px] placeholder:text-base placeholder:font-light placeholder:text-white"
          onChange={onChangeForm}
          value={value}
          autoComplete="off"
        />
      )}
    </Fragment>
  );
};

export default TextInputAc;
