import React from "react";
import { Link } from "react-router-dom";

const MyHoldings = ({ data, title }) => {
  return (
    <div className="w-[50%] bg-bgprimary_ob mr-4 rounded-2xl lg:mx-auto lg:w-full lg:mt-4">
      <h3 className="mt-8 ml-6">{title}</h3>
      <div className="flex flex-col pb-11">
        {data.map((holding) => (
          <div
            className="flex border-photo_ob border-b-[2px] w-[90%] mx-auto justify-between last:border-b-0"
            key={holding.project_id}
          >
            <div className="flex flex-col pb-4">
              <span className="text-base mt-8 ml-11">
                {holding.project_name}
              </span>
              <span className="text-sm ml-11">
                Invested: $
                {new Intl.NumberFormat().format(holding.invested_capital)}
              </span>
            </div>
            <Link
              to={`/overview/holding/${holding.investment_id}`}
              key={holding.investment_id}
              className="text-sm rounded-2xl bg-button_ob py-1 w-[100px] my-auto text-center"
            >
              See Details
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MyHoldings;
