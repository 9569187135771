import React, { useEffect, useState } from 'react';
import Modal from '../../../../components/Modal/Modal';
import back from '../../../../images/index/back-button.svg';

const DefinitionModal = ({ setModal }) => {
  const [scrollbar, setScroll] = useState(false);

  //Smooth scroll
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener('click', function (e) {
      e.preventDefault();
      document
        .querySelector(this.getAttribute('href'))
        .scrollIntoView({ behavior: 'smooth' });
    });
  });

  useEffect(() => {
    if (
      document.getElementById('main-container').clientHeight >
      document.body.clientHeight
    ) {
      setScroll(true);
    }
  }, []);

  return (
    <Modal scroll={scrollbar}>
      <div id="main-container">
        <div className="flex flex-col items-center justify-center">
          <div className="flex">
            <img
              alt="back-button"
              src={back}
              className="cursor-pointer mb-2 mr-5"
              onClick={() => setModal(false)}
            />
            <h1 className="text-xl font-thin">
              Amendments to Accredited Investor Definition
            </h1>
          </div>
          <div className="flex flex-col my-3">
            <h2 className="text-lg mb-1 font-semibold">
              Small Entity Compliance Guide
              <a
                href="#btn-guide-2"
                id="btn-guide-1"
                className="text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB] ml-1 font-medium"
              >
                [1]
              </a>
            </h2>
            <p className="text-tiny">
              On August 26, 2020, the U.S. Securities and Exchange Commission
              (“Commission”) adopted amendments to the definition of “accredited
              investor” under the Securities Act of 1933 (“Securities Act”). The
              amendments update and improve the definition to more effectively
              identify institutional and individual investors that have the
              knowledge and expertise to participate in private capital markets.
              The amendments are effective December 8, 2020
            </p>
          </div>
          <div className="flex flex-col my-3">
            <h2 className="text-lg mb-1 font-semibold">
              What changes were made by the amendments?
            </h2>
            <ol className="marker:text-white pl-5 list-decimal font-medium">
              <li className="mb-2">
                Add categories of natural persons eligible to qualify as
                accredited investors.
              </li>
            </ol>
            <p className="text-tiny">
              <h3 className="font-thin text-base">
                Professional certifications and designations and other
                credentials
              </h3>
              The amendments created an accredited investor category for
              individual investors who hold, in good standing, certain
              professional certifications and designations and other credentials
              designated by the Commission as qualifying for accredited investor
              status. The Commission designated three certifications and
              designations administered by the Financial Industry Regulatory
              Authority, Inc. as qualifying for accredited investor status:
            </p>
            <ul className="marker:text-white pl-5 list-disc text-tiny my-2">
              <li>Licensed General Securities Representative (Series 7);</li>
              <li>
                Licensed Investment Adviser Representative (Series 65); and
              </li>
              <li>
                Licensed Private Securities Offerings Representative (Series
                82).
              </li>
            </ul>
            <p className="text-tiny mb-3">
              Individuals holding any of these three designations in good
              standing can qualify as accredited investors. Whether a person
              holds one of the designations in good standing is specific to that
              designation, and persons seeking accredited investor status under
              this category should consult FINRA rules and any state rules
              applicable to them. For example, a person seeking accredited
              investor status by passing the Series 65 exam would also need to
              be licensed as an investment adviser representative in her state
              and would need to comply with all state-specific licensing
              requirements (e.g., paying annual fees, etc.).
              <p className="text-tiny mt-2">
                The Commission may designate in the future additional qualifying
                professional certifications, designations, and other credentials
                by order. Requests for Commission consideration, which must
                address how a particular certification, designation, or
                credential satisfies the nonexclusive list of attributes set
                forth in the new rule, may be submitted at{' '}
                <a
                  className="text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB]"
                  href="mailto:investorcredentials@sec.gov"
                >
                  investorcredentials@sec.gov.
                </a>
              </p>
            </p>
            <h3 className="font-thin">Knowledgeable employees</h3>
            <p className="text-tiny mb-3">
              The amendments also created an accredited investor category for
              individual investors who are knowledgeable employees of certain
              private funds. To qualify as an accredited investor under this
              category, an investor must be a “knowledgeable employee,” as
              defined in Rule 3c–5(a)(4) under the Investment Company Act of
              1940 (the “Investment Company Act”), of the private fund issuer of
              the securities being offered or sold. This includes directors and
              certain executive officers of the private fund, or of an
              affiliated person of the private fund that manages the investment
              activities of the private fund (“affiliated management person”).
              This also includes employees who participate in the investment
              activities of the private fund or other private funds or
              investment companies managed by the affiliated management person.
            </p>
            <p className="text-tiny mb-3">
              A private fund issuer is an issuer that would be an investment
              company, as defined in section 3 of such the Investment Company
              Act, but for the exclusion provided by either section 3(c)(1) or
              section 3(c)(7) of such Act.
            </p>
            <p className="text-tiny mb-3">
              A natural person qualifying as an accredited investor based on her
              status as a knowledgeable employee is an accredited investor only
              for offerings by the private fund and other private funds managed
              by their employer. She cannot use her status as a knowledgeable
              employee to qualify as an accredited investor to invest in other
              offerings.
            </p>
            <h3 className="font-thin">Family clients of family offices</h3>
            <div className="text-tiny">
              A natural person may also qualify as an accredited investor based
              on her status as a family client of a family office. To qualify,
              an investor must:
              <ul className=" marker:text-white pl-5 list-disc my-2">
                <li className="mt-2">
                  come within the definition of “family client” in rule
                  202(a)(11)(G)–1 under the Investment Advisers Act of 1940 (the
                  “Advisers Act”),
                </li>
                <li className="my-2">
                  be a family client of a family office that itself qualifies as
                  an accredited investor, and
                </li>
                <li>
                  have her investment be directed by a person who has such
                  knowledge and experience in financial and business matters
                  that such family office is capable of evaluating the merits
                  and risks of the prospective investment.
                </li>
              </ul>
            </div>
            <p className="text-tiny">
              See the discussion below of the “family office” category of
              accredited investor for additional information.
            </p>
            <ol className="list-decimal marker:text-white pl-5 mt-3">
              <li value="2" className="font-medium">
                Add categories of entities eligible to qualify as accredited
                investors.
              </li>
            </ol>
            <p className="text-tiny">
              The amendments created several accredited investor categories for
              entities:
            </p>
            <ul className="marker:text-white list-disc pl-5 mt-2 text-tiny">
              <li>investment advisers registered with the Commission,</li>
              <li className="my-1">state-registered investment advisers,</li>
              <li>exempt reporting advisers,</li>
              <li className="my-1">rural business investment companies,</li>
              <li>
                limited liability companies with more than $5 million in assets,
              </li>
              <li className="my-1">
                certain family offices and family clients, and
              </li>
              <li>entities owning investments in excess of $5 million.</li>
            </ul>
            <h3 className="font-thin mt-3">Investment advisers</h3>
            <p className="text-tiny mb-3">
              An investment adviser may qualify for accredited investor status
              if it is either registered with the Commission, registered with a
              state, or is relying on an exemption from registering with the
              Commission under section 203(l) or (m) of the Advisers Act.
            </p>
            <h3 className="font-thin">Rural business investment companies</h3>
            <p className="text-tiny mb-3">
              Rural business investment companies, as defined in defined in
              Section 384A of the Consolidated Farm and Rural Development Act,
              qualify as accredited investors under the amendments.
            </p>
            <h3 className="font-thin">Limited liability companies</h3>
            <p className="text-tiny mb-3">
              The amendments codified a long-standing staff interpretation
              allowing limited liability companies with more than $5 million in
              assets to qualify as accredited investors. Such limited liability
              companies may not be formed for the specific purpose of acquiring
              the securities offered.
            </p>
            <h3 className="font-thin">
              Family offices and entities that are family clients
            </h3>
            <div className="text-tiny mb-3">
              To qualify as an accredited investor, a family office:
              <ul className="marker:text-white pl-5 list-disc">
                <li className="mt-2">
                  must come within the definition of “family office” in Rule
                  202(a)(11)(G)–1 under the Advisers Act,
                </li>
                <li className="my-1">
                  must have assets under management in excess of $5 million,
                </li>
                <li>
                  cannot be formed for the specific purpose of acquiring the
                  securities offered, and
                </li>
                <li className="mt-1">
                  must have its prospective investments be directed by a person
                  who has such knowledge and experience in financial and
                  business matters that such family office is capable of
                  evaluating the merits and risks of the prospective investment.
                </li>
              </ul>
              <p>
                Similar to family clients who are natural persons, to qualify as
                an accredited investor based on status as a family client, an
                entity must (i) come within the definition of “family client” in
                rule 202(a)(11)(G)–1 under the Advisers Act, (ii) be a family
                client of a family office that itself qualifies as an accredited
                investor, and (iii) have its investment be directed by the
                family office.
              </p>
            </div>
            <h3 className="font-thin">
              All other entities meeting an investments test
            </h3>
            <div className="text-tiny">
              <p className="mb-3">
                The amendments also created a catch all for entities:
              </p>
              <ul className="marker:text-white pl-5 list-disc mb-2">
                <li className="mt-2">
                  not already considered accredited investors under the
                  definition,
                </li>
                <li className="my-1">
                  that were not formed for the specific purpose of acquiring the
                  securities offered, and
                </li>
                <li>that own investments in excess of $5 million.</li>
              </ul>
              <p>
                “Investments” is defined in rule 2a51–1(b) under the Investment
                Company Act to include securities; real estate, commodity
                interests, physical commodities, and non-security financial
                contracts held for investment purposes; and cash and cash
                equivalents.
              </p>
            </div>
          </div>
          <div className="my-4 text-tiny">
            <h2 className="font-semibold text-lg">Other Resources</h2>
            <p className="mt-2">
              The adopting release for these amendments can be found on the
              Commission’s website at{' '}
              <a
                className="text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB]"
                href="https://www.sec.gov/rules/final/2020/33-10824.pdf"
              >
                https://www.sec.gov/rules/final/2020/33-10824.pdf.{' '}
              </a>
            </p>
          </div>
          <div className="text-tiny">
            <h2 className="font-semibold text-lg"> Contacting the SEC </h2>
            <p className="mt-2">
              Questions on the amendments and on other Commission regulatory
              matters concerning small companies may be directed to the
              Division’s Office of Small Business Policy at (202) 551-3460 or{' '}
              <a
                href="mailto:smallbusiness@sec.gov"
                className="text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB]"
              >
                {' '}
                smallbusiness@sec.gov.{' '}
              </a>{' '}
              The Commission’s Division of Investment Management’s Chief
              Counsel’s Office is also available to assist small entities and
              others with questions regarding the rule amendments. You may
              contact the Office for this purpose at 202-551-6825 or
              <a
                href="mailto:IMOCC@sec.gov"
                className="text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB]"
              >
                {' '}
                IMOCC@sec.gov.{' '}
              </a>{' '}
            </p>
          </div>
        </div>
        <hr className="border-1 border-orange_ob w-[38%] my-4" />
        <div className="flex text-tiny">
          <p>
            <a
              href={
                'https://www.sec.gov/corpfin/secg-accelerated-filer-and-large-accelerated-filer-definitions'
              }
              id="btn-guide-2"
              className="mr-2 text-[#2881FB] hover:underline decoration-1 decoration-solid decoration-[#2881FB] font-medium"
            >
              [1]
            </a>
            This guide was prepared by the staff of the U.S. Securities and
            Exchange Commission as a “small entity compliance guide” under
            Section 212 of the Small Business Regulatory Enforcement Fairness
            Act of 1996, as amended. The guide summarizes and explains the rules
            adopted by the Commission, but is not a substitute for any rule
            itself. Only the rule itself can provide complete and definitive
            information regarding its requirements.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default DefinitionModal;
