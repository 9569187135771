import React, { useState } from 'react';
import { serviceAUTH, serviceBASE } from '../axios';
import AuthContext from './authContext';

const AuthState = ({ children }) => {
  const [auth, isAuth] = useState(false);
  const [validate, setValidate] = useState(null);
  const [errMsg, setErrMsg] = useState('');

  const resetPassword = async (data) => {
    try {
      const response = await serviceBASE.patch('/users/me/reset-password', {
        email: data,
      });
      if (response.status === 200) {
        setErrMsg('');
      }

      setValidate(true);
    } catch (err) {
      if (!err.response) {
        return setErrMsg('No server response');
      } else if (err.response.status === 401) {
        return setErrMsg('Ups, Email is incorrect');
      }
    }
  };

  const LogIn = async (data) => {
    try {
      const response = await serviceAUTH.post('/login', {
        username: data.username,
        password: data.password,
      });
      setValidate(true);

      if (response.status === 200) {
        setErrMsg('');
      }

      localStorage.setItem('jwt', response.data.token);
    } catch (err) {
      if (err) setValidate(validate === false ? null : false);
      if (!err.response) {
        return setErrMsg('No server response');
      } else if (err.response.status === 400) {
        setErrMsg('');
        return setErrMsg('Missing Username or Password');
      } else if (err.response.status === 401) {
        setErrMsg('');
        return setErrMsg(`${err.response.data.message}`);
      } else {
        setErrMsg('Login failed');
      }
    }
  };

  const registerUser = async (data) => {
    try {
      const response = await serviceAUTH.post('/newRegister', data);
      if (response.status === 200) {
        setErrMsg('Successfully registered');
      }
      setValidate(true);
      localStorage.setItem('jwt', response.data.token);
    } catch (err) {
      if (err) setValidate(validate === false ? null : false);
      if (!err.response) {
        return setErrMsg('No server response');
      } else if (err.response.status === 400) {
        return setErrMsg(`${err.response.data.message}`);
      } else if (err.response.status === 401) {
        return setErrMsg('Unauthorized');
      } else {
        setErrMsg('Register failed');
      }
    }
  };

  const userAuth = () => {
    const token = localStorage.getItem('jwt');
    if (token) {
      isAuth(true);
    }
  };

  const removeToken = () => {
    const token = localStorage.getItem('jwt');
    if (token) {
      localStorage.removeItem('jwt');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        validate,
        auth,
        errMsg,
        setValidate,
        setErrMsg,
        resetPassword,
        LogIn,
        registerUser,
        userAuth,
        removeToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
