import React from "react";

const OvwMsg = ({ message }) => {
  return (
    <span className="text-white font-normal text-sm my-auto mx-auto py-4">
      {message}
    </span>
  );
};

export default OvwMsg;
