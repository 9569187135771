import Modal from '../../../components/Modal/Modal';
import back from '../../../images/index/back-button.svg';
import { abbreviateDate } from '../../../store/utils';
import {VscFilePdf} from "react-icons/vsc"

const Documents = ({
  title,
  type,
  props,
  setProps,
  doc,
  _id,
  newModal,
  isInvestor,
}) => {

  

  const open = (string_img, event) => {
    event.preventDefault();
    let investor = isInvestor ? 'investoraccount' : 'onboarding';
    let link = `https://backend.onebric.com/users/${_id}/${investor}/${string_img}`;
    window.open(link, "_blank");
  }

  return (
    <Modal newModal={newModal}>
      <div className="flex flex-col" id="container">
        <div className="flex w-[100%]">
          <img
            alt="back-button"
            src={back}
            className="text-2xl mb-2 self-start cursor-pointer"
            onClick={() =>
              setProps({
                ...props,
                [type]: false,
              })
            }
          />

          <p className="self-center mx-auto text-lg font-normal">{title}</p>
        </div>

        {doc.mimetype !== 'application/pdf' && (
          <img
            alt={type}
            id="document"
            src={`https://backend.onebric.com/users/${_id}/${
              isInvestor ? 'investoraccount' : 'onboarding'
            }/${doc?.filename}`}
            className="mr-4 object-contain h-96"
          />
        )}

        {doc.mimetype === 'application/pdf' && (
          <button onClick={(event) => open(doc?.filename, event)}>
            <VscFilePdf style={{ fontSize : "4.5rem", margin: "15% auto"}} />
          </button>
        )}

        <span className="self-center mt-6">Status {doc?.status}</span>
        {doc?.expirationDate && (
          <span className="self-center mt-2 text-sm">
            Expiration Date: {abbreviateDate(new Date(doc?.expirationDate))}
          </span>
        )}
      </div>
    </Modal>
  );
};

export default Documents;
