import React from "react";

const Message = ({ succesfull }) => {
  return (
    <div className="text-green-500 text-center ml-6 mt-4 mb-[-1rem] text-base font-medium">
      <span>{succesfull} </span>
    </div>
  );
};

export default Message;
