import React, { Fragment, useMemo } from 'react';

import { pairsArr } from '../../../../store/utils';

const Updates = ({ data }) => {
  const docInfo = data?.updates;

  const formattedPairs = useMemo(() => pairsArr(docInfo), [docInfo]);

  return (
    <>
      {docInfo?.length === 0 || docInfo?.length === undefined ? (
        <span className="text-white font-normal text-sm my-8">
          No updates for this project yet.
        </span>
      ) : (
        formattedPairs
      )}
    </>
  );
};

export default Updates;
