import { createContext, useEffect, useState } from 'react';
import { serviceBASE } from '../../config/axios';

export const NotificationsContext = createContext();

const NotificationsProvider = (props) => {
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [pending, setPending] = useState([]);
  const [isReadPending, setIsReadPending] = useState([]);
  const [isRead, setIsRead] = useState([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(2);
  const [onboardNoti, setOnboardNoti] = useState(false);

  const refetch = async () => {
    const response = await serviceBASE.get(`/users/me/notifications`);
    if (response.data.docs.notifications_unread?.length === 0) {
      console.log('No notifications yet');
    } else if (response.data.docs.notifications_unread?.length >= 1) {
      console.log('Notifications to be read');
    }
  };

  useEffect(() => {
    const token = localStorage.getItem('jwt');
    if (token) {
      const consulta = async () => {
        const dataUser = await serviceBASE.get('/users/me');
        const response = await serviceBASE.get(
          `/newNotifications/${dataUser?.data._id}/`
        );
        const responseActionItems = await serviceBASE.get(
          `/actionItems/${dataUser?.data._id}/`
        );
        try {
          setData([...data, ...response?.data.docs.notifications_unread]);
          setIsRead([...isRead, ...response?.data.docs.notifications_read]);

          setPage(response?.data.docs.page);
          setPages(response?.data.docs.pages);

          setUserData(dataUser?.data);

          setPending([
            ...pending,
            ...responseActionItems?.data.docs.actionItems_unread,
          ]);
          // prettier-ignore
          setIsReadPending([...isReadPending, ...responseActionItems?.data.docs.actionItems_read]);

          if (data.length === 0 || pending.length === 0) {
            setPage(page + 1);
          }
        } catch (err) {
          console.log(err);
        }
      };

      consulta();

      const timer = setInterval(() => {
        refetch();
      }, 600000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (!userData?.completed_onboarding) {
      setOnboardNoti(true);
    } else if (userData?.completed_onboarding) {
      setOnboardNoti(false);
    }
  }, [userData]);

  return (
    <NotificationsContext.Provider
      value={{
        data,
        isRead,
        userData,
        onboardNoti,
        pending,
        page,
        pages,
        isReadPending,
        refetch,
        setData,
        setIsRead,
        setUserData,
        setPending,
        setIsReadPending,
        setOnboardNoti,
        setPage,
      }}
    >
      {props.children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsProvider;
