import axios from 'axios';

// BASE URL
export const serviceBASE = axios.create({
  baseURL: process.env.REACT_APP_URL_BASE,
  'Content-Type': 'application/json',
});

// STAGING URL BASE

export const serviceSTAGING = axios.create({
  baseURL: process.env.REACT_APP_STAGING_URL_BASE,
  'Content-Type': 'application/json',
});

export const serviceSTAGINGAUTH = axios.create({
  baseURL: process.env.REACT_APP_STAGING_URL_AUTH,
  'Content-Type': 'application/json',
});

// LOCAL AUTH URL
export const serviceLOCAL = axios.create({
  baseURL: process.env.REACT_APP_URL_LOCAL,
  'Content-Type': 'application/json',
});

// LOCAL AUTH URL
export const serviceLOCALAUTH = axios.create({
  baseURL: process.env.REACT_APP_URL_LOCAL_AUTH,
  'Content-Type': 'application/json',
});

// AUTH URL
export const serviceAUTH = axios.create({
  baseURL: process.env.REACT_APP_URL_AUTH,
  'Content-Type': 'application/json',
});
