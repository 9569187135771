import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { serviceBASE } from '../../../../config/axios';

import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import Modal from '../../../../components/Modal/Modal';
import Error from '../../../../components/Messages/Error';
import Message from '../../../../components/Messages/Message';
import Spinner from '../../../../components/spinner/Spinner';

import back from '../../../../images/index/back-button.svg';
import 'react-datepicker/dist/react-datepicker.css';
import './TimePicker.css';

const InvestForm = ({ setForm, data }) => {
  let [Msg, setMsg] = useState();
  let [amount, setAmount] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [time, onChange] = useState('10:00');
  let [success, setSuccess] = useState();
  const [selected, setSelected] = useState('');
  const [isValidate, setIsValidate] = useState(false);
  const [sp, setSp] = useState(null);

  const [userInfo, setUserInfo] = useState();
  const [investorsAcc, setInvestorsAcc] = useState();
  const [isChoosed, setIsChoosed] = useState(false);
  const [nameAcc, setNameAcc] = useState('');
  const [investorAccInfo, setInvestorAccInfo] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    const getInvAcc = (name) => {
      const isNaturalPerson = investorsAcc?.filter(
        (acc) => acc.name === name
      )[0];
      const isEntity = investorsAcc?.filter(
        (acc) => acc.company_name === name
      )[0];

      return isNaturalPerson || isEntity || userInfo;
    };

    setInvestorAccInfo(getInvAcc(nameAcc));
  }, [nameAcc]);

  useEffect(() => {
    const consulta = async () => {
      setSp(true);
      const response = await serviceBASE.get(`/users/me`);
      const response2 = await serviceBASE.get(
        `/users/me/investoracc/${response?.data?._id}`
      );
      try {
        setUserInfo(response.data);
        setInvestorsAcc(response2.data.docs);
        setSp(false);
      } catch (err) {
        console.log(err);
      }
    };
    consulta();
  }, []);

  useEffect(() => {
    if (isValidate === true) {
      setSp(true);
      setTimeout(() => {
        setSp(false);
        window.location.reload(navigate('/'));
      }, 2500);
    }
  }, [isValidate]);

  useEffect(() => {
    takeButtons();
  }, [nameAcc]);

  const onChangeRadio = (e) => {
    setSelected(e.target.value);
  };

  const investData = async (a, d, v) => {
    try {
      const response2 = await serviceBASE.post('/investments', {
        investor_account: investorAccInfo?._id,
        project_id: data?._id,
        contact_date: d,
        contact_time: v,
        investment_amount_request: a,
        investment_date: new Date(),
      });
      if (response2.status === 200) {
        setMsg('');
        setSuccess('Thank you! We will be contacting by email shortly');
        setIsValidate(true);
      }
    } catch (err) {
      if (err) {
        if (
          err.response.data.message ===
          'Ups, already exists an investment for this project'
        ) {
          setSuccess('');
          setMsg(
            'You have already submitted a request for this project, our specialists will contact you shortly.'
          );
        }
        if (err.response.status === 400) {
          setSuccess('');
          setMsg(err.response.data.message);
        }
      }
    }
  };

  const onSubmitPress = (a, d, v) => {
    if (!d && !v && !a) {
      setMsg('Fill in all the information to continue.');
      return;
    }

    if (selected === '') {
      setMsg('Select an option to continue');
      return;
    }
    if (selected === 'Money') {
      if (!a) {
        setMsg('Select an amount to continue.');
        return;
      }
      if (a < data?.min_invested) {
        setMsg(`Amount must be greater than $${data?.min_invested}`);
        return;
      }
    }
    investData(a, d, v);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmitPress(amount, startDate, time);
  };

  const takeButtons = () => {
    const accounts = document.getElementsByTagName('button');
    let newAcc = [];
    let difAccs;
    for (let i = 0; i < accounts.length; i++) {
      if (accounts[i].id === 'investors_accounts') {
        newAcc.push(accounts[i]);
      }
    }
    newAcc.map((i, index) => {
      if (nameAcc === i.innerHTML) {
        difAccs = newAcc.filter((e, indice) => indice !== index);
        difAccs.map((e) => {
          e.style.backgroundColor = 'rgb(107, 114, 128)';
          e.style.opacity = '0.5';
        });
        i.style.backgroundColor = 'rgb(107, 114, 128)';
        i.style.opacity = '1';
      }
    });
  };

  return (
    <Modal scroll={true}>
      {sp && <Spinner />}
      <div className="flex font-montserrat">
        <img
          alt="back"
          src={back}
          className="cursor-pointer text-2xl"
          onClick={() => setForm(false)}
        />
        <h1 className="text-center text-lg font-medium self-center mx-auto text-white">
          Invest Now
        </h1>
      </div>
      <div className="flex flex-col text-white">
        <p className="text-lg font-medium mt-4 mb-2">
          Hi {userInfo?.name}, please complete the fields below, we will reach
          you soon
        </p>
        <span className="text-md font-light">
          Pick a contact date and time:
        </span>{' '}
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center mt-4">
            <div className="flex mb-4">
              <span className="mr-4">Date</span>{' '}
              <DatePicker
                className="text-white rounded-2xl w-[140px] text-center bg-pickers_ob"
                closeOnScroll={true}
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </div>
            <div className="flex">
              <span className="mr-4">Time</span>{' '}
              <TimePicker
                className="text-white rounded-2xl w-[140px] text-center bg-pickers_ob"
                disableClock={true}
                onChange={onChange}
                value={time}
              />
            </div>
            <span className="text-md mt-4 mb-2">
              What is the amount you would like to invest?{' '}
            </span>
            <div className="w-[400px] rounded-2xl">
              <div className="my-2">
                <input
                  type="radio"
                  value="Money"
                  className="ml-2"
                  onChange={onChangeRadio}
                  checked={selected === 'Money'}
                />{' '}
                <span className="text-md mx-2">Choose amount (USD): </span>{' '}
                <input
                  type="number"
                  name="amount"
                  onChange={(e) => setAmount(e.target.value)}
                  className="outline-0 border-0 bg-transparent text-center border-b-2 w-[100px]"
                />
              </div>
              <div className="mb-4">
                <input
                  type="radio"
                  value="Sure"
                  className="ml-2"
                  onChange={onChangeRadio}
                  checked={selected === 'Sure'}
                />{' '}
                <span className="text-md mx-2">I'm not sure yet </span>{' '}
              </div>
            </div>
            <span>
              Select the Investment Account you would like to use for this
              opportunity
            </span>
            {Msg ? (
              <span>
                {' '}
                <Error message={Msg} />{' '}
              </span>
            ) : (
              success && (
                <span className="mb-4">
                  <Message succesfull={success} />
                </span>
              )
            )}
            <button
              type="button"
              id="investors_accounts"
              onClick={(e) => {
                setIsChoosed(true);
                setNameAcc(e.target.textContent);
              }}
              className={
                isChoosed
                  ? 'bg-bggray_ob w-[75%] mt-4 py-1 rounded-md bg-opacity-50 hover:drop-shadow-xl'
                  : 'bg-bggray_ob w-[75%] mt-4 py-1 rounded-md hover:bg-gray-500 hover:drop-shadow-xl'
              }
            >
              {userInfo?.name}
            </button>
            {investorsAcc?.map((accs) => (
              <button
                key={accs?._id}
                type="button"
                id="investors_accounts"
                onClick={(e) => {
                  setIsChoosed(true);
                  setNameAcc(e.target.textContent);
                }}
                className={
                  isChoosed
                    ? 'bg-bggray_ob w-[75%] mt-4 py-1 rounded-md bg-opacity-50 hover:drop-shadow-xl'
                    : 'bg-bggray_ob w-[75%] mt-4 py-1 rounded-md hover:bg-gray-500 hover:drop-shadow-xl'
                }
              >
                {accs?.name ? accs?.name : accs?.company_name}
              </button>
            ))}
            <button
              type="button"
              onClick={() => navigate('/profile/useraccount/register')}
              className="bg-bggray_ob w-[65%] mt-4 py-1 rounded-md hover:bg-gray-500 hover:drop-shadow-xl"
            >
              + Add New Investment Account
            </button>
            <button
              type="submit"
              className={
                !isChoosed
                  ? `hidden rounded-2xl w-[100px] font-medium h-[35px] bg-orange_ob hover:bg-orange_ob/80 hover:shadow-xl  mt-4`
                  : `rounded-2xl w-[100px] font-medium h-[35px] bg-orange_ob hover:bg-orange_ob/80  hover:shadow-xl  mt-4`
              }
            >
              {' '}
              Submit{' '}
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default InvestForm;
