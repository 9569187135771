import React from 'react';
import { useRef } from 'react';

const Tooltip = ({ children, tooltip }) => {
  const tooltipRef = useRef(null);
  const container = useRef(null);

  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { left } = container.current.getBoundingClientRect();

        tooltipRef.current.style.left = clientX - left + 'px';
      }}
      className="group relative inline-block w-[30%]"
    >
      {children}
      {tooltip ? (
        <span
          ref={tooltipRef}
          className="z-10 group-hover:visible opacity-0 group-hover:opacity-100 transition bg-bgprimary_ob text-white p-1 px-2 rounded absolute top-full mt-2 whitespace-nowrap"
        >
          {tooltip}
        </span>
      ) : null}
    </div>
  );
};

export default Tooltip;
