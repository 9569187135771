import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../../images/index/logo.svg';
import { NotificationsContext } from '../../../context/notifications/NotificationsContext';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';

const Welcome = () => {
  const { onboardNoti } = useContext(NotificationsContext);

  return (
    <>
      {!onboardNoti ? (
        <ErrorPage />
      ) : (
        <div className="flex flex-col items-center text-white font-montserrat">
          <img
            src={logo}
            alt="logo"
            className="object-contain w-[200px] h-[150px] mt-12"
          />
          <div className="flex flex-col items-center">
            <span className="text-center text-base mb-4">
              Thank you for joining the ONEBRIC platform!
            </span>
            <span className="text-center text-base">
              Add an Investment Account to be able to participate in any
              opportunity
            </span>

            <Link
              to="/profile/useraccount/register"
              reloadDocument={true}
              className="bg-bggray_ob w-[50%] text-center mt-12 py-1 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl"
            >
              + Add New Investment Account
            </Link>

            <Link
              to="/"
              reloadDocument={true}
              className="bg-transparent w-[50%] border-2 mt-4 py-1 text-center rounded-2xl mb-8 hover:bg-gray-500 hover:drop-shadow-xl"
            >
              Not Now
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default Welcome;
