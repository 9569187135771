import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { InvestorAccContext } from '../../../../context/investoracc/InvestorAccContext';

import Error from '../../../../components/Messages/Error';
import TextInputAc from '../../../modal/TextInputAc';
import PlaceAutoComplete from '../../../../components/SearchAddres/PlaceAutoComplete';
import DefinitionModal from '../../../login/views/modal/DefinitionModal';

const UsEntity = ({
  type,
  error,
  personalInfo,
  setError,
  onChangeForm,
  setPersonalInfo,
}) => {
  const { setData } = useContext(InvestorAccContext);

  const { company_name, location, type_investor } = personalInfo;

  const [showMenu, setShowMenu] = useState(false);
  const [selected, setSelected] = useState(0);
  const [authError, setAuthError] = useState('');
  const [secondSelect, setSecondSelect] = useState(0);
  const [googleValue, setGoogleValue] = useState(null);
  const [showModal, setModal] = useState(false);

  const navigate = useNavigate();

  const onChangeRadio = (e, radio) => {
    if (radio === 'first') {
      setSelected(e);
    } else if (radio === 'second') {
      setSecondSelect(e);
    }
  };

  useEffect(() => {
    if (googleValue !== null) {
      setPersonalInfo({
        ...personalInfo,
        location: {
          ...location,
          country: 'United States',
          address: googleValue?.label,
          city: googleValue?.city,
          state: googleValue?.state,
          zip_code: googleValue?.postalCode,
        },
      });
    }
  }, [googleValue]);

  const handleSelection = (selectedValue, stateKey) => {
    setPersonalInfo({
      ...personalInfo,
      [stateKey]: selectedValue === 1,
    });
  };

  useEffect(() => {
    handleSelection(selected, 'authorized_represent_entity');
  }, [selected]);

  useEffect(() => {
    handleSelection(secondSelect, 'accredited_investor');
  }, [secondSelect]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      company_name === '' ||
      type_investor === '' ||
      location.address === '' ||
      location.city === '' ||
      location.state === '' ||
      location.zip_code === ''
    ) {
      return setError('Please complete all the fields');
    }
    setError('');
    if (!personalInfo.authorized_represent_entity) {
      return setAuthError(
        '(Authorization is required to proceed with registration)'
      );
    }
    setShowMenu(false);
    setError('');
    setAuthError('');
    setData(personalInfo);
    const timer = setTimeout(() => {
      navigate(`/profile/useraccount/register/${type}/documents`);
    }, 1000);
    return () => clearTimeout(timer);
  };

  document.onclick = function (e) {
    if (e.target.id !== 'type_entitys') {
      setShowMenu(false);
    }
  };

  return (
    <>
      {showModal && <DefinitionModal setModal={setModal} />}
      <form className="w-[40%] mt-4" onSubmit={handleSubmit}>
        <div className="flex flex-col mt-2">
          <TextInputAc
            name={'company_name'}
            value={company_name}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-2" htmlFor="firstname">
            NAME OF ENTITY
          </label>
        </div>
        <div className="flex flex-col my-2">
          <button
            type="button"
            id="type_entitys"
            onClick={() => {
              if (showMenu === false) {
                setShowMenu(true);
              } else setShowMenu(false);
            }}
            className="flex outline-[0px] bg-transparent border-photo_ob border-b-[2px] placeholder:text-base placeholder:font-light placeholder:text-white"
          >
            {type_investor}
            <svg
              className="-mr-1 h-5 w-5 ml-auto"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
              id="type_entitys"
            >
              <path
                fillRule="evenodd"
                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                clipRule="evenodd"
                id="type_entitys"
              />
            </svg>
          </button>
          <label className="text-xs mt-2" htmlFor="lastname">
            TYPE OF ENTITY
          </label>
        </div>
        <div>
          <div
            className={
              !showMenu
                ? 'hidden'
                : 'animate-fade-in-down flex flex-col absolute z-10 ml-3 mt-0 w-[30%] origin-top-right rounded-md bg-contenido ring-1 ring-white shadow-lg focus:outline-none'
            }
          >
            <button
              className="py-1 mt-2 hover:bg-slate-500 rounded-md"
              type="button"
              onClick={() => {
                setShowMenu(false);
                setPersonalInfo({
                  ...personalInfo,
                  type_investor: 'Corporation',
                });
              }}
            >
              Corporation
            </button>
            <button
              className="py-1 my-2 w-[100%] hover:bg-slate-500 rounded-md"
              type="button"
              onClick={() => {
                setShowMenu(false);
                setPersonalInfo({
                  ...personalInfo,
                  type_investor: 'TrustOrEstate',
                });
              }}
            >
              Trust / Estate
            </button>
            <button
              className="py-1 my-2 w-[100%] hover:bg-slate-500 rounded-md"
              type="button"
              onClick={() => {
                setShowMenu(false);
                setPersonalInfo({
                  ...personalInfo,
                  type_investor: 'LLC',
                });
              }}
            >
              LLC
            </button>{' '}
            <button
              className="py-1 my-2 w-[100%] hover:bg-slate-500 rounded-md"
              type="button"
              onClick={() => {
                setShowMenu(false);
                setPersonalInfo({
                  ...personalInfo,
                  type_investor: 'Other',
                });
              }}
            >
              Other
            </button>{' '}
          </div>
        </div>
        <div className="flex flex-col my-2">
          <div className="border-b-2 pb-1 bg-transparent">
            <PlaceAutoComplete value={googleValue} setValue={setGoogleValue} />
          </div>
          <label className="text-xs mt-1" htmlFor="address">
            ADDRESS
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'city'}
            value={location.city}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            CITY
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'state'}
            value={location.state}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            STATE
          </label>
        </div>
        <div className="flex flex-col my-2">
          <TextInputAc
            name={'zip_code'}
            value={location.zip_code}
            onChangeForm={onChangeForm}
            type={'text'}
          />
          <label className="text-xs mt-1" htmlFor="country">
            ZIP CODE
          </label>
        </div>

        {error && <Error message={error} />}

        <div className="w-[100%] mt-4">
          <div className="">
            <input
              type={'radio'}
              checked={selected === 1}
              onClick={(e) => {
                selected === 0 && onChangeRadio(1, 'first');
              }}
              className="mr-4"
            />
            <span className="text-base font-base ">
              I certify that I am an authorized individual to act on behalf of
              Entity above.{' '}
              {authError && (
                <span className="text-[#F80F00] ml-2 text-sm absolute">
                  {' '}
                  {authError}{' '}
                </span>
              )}
            </span>{' '}
          </div>
          <div className="mt-4">
            <input
              type={'radio'}
              checked={secondSelect === 1}
              onClick={(e) => {
                secondSelect === 0
                  ? onChangeRadio(1, 'second')
                  : onChangeRadio(0, 'second');
              }}
              className="mr-4"
            />
            <span className="text-base font-base">
              I certify I am an “Accredited Investor” defined by the U.S.
              Securities and Exchange Commission.
            </span>{' '}
            <button
              className="text-sm font-base text-[#2881FB]"
              type={'button'}
              onClick={() => setModal(true)}
            >
              (Read definition here)
            </button>
          </div>
        </div>

        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="bg-bggray_ob w-[25%] py-1 rounded-2xl my-8 hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default UsEntity;
