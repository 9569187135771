import React, { useContext, useEffect, useState } from 'react';
import { NotificationsContext } from '../../context/notifications/NotificationsContext';
import { serviceBASE } from '../../config/axios';

import MiniSpinner from '../../components/MiniSpinner/MiniSpinner';
import NotificationView from './view/NotificationView';
import Tabs from '../../components/tab-indicator/TabIndicator';

const Notifications = () => {
  const {
    data,
    pending,
    page,
    isRead,
    isReadPending,
    pages,
    userData,
    setPage,
    setData,
    setIsReadPending,
    setPending,
    setIsRead,
  } = useContext(NotificationsContext);

  const [spin, setSpin] = useState(false);

  const [notiSelector, setNotiSelector] = useState({
    notifications: true,
    pendingItems: false,
  });

  window.onscroll = function () {
    if (
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight
    ) {
      scrollToEnd();
    }
  };

  const scrollToEnd = () => {
    if (page <= pages) {
      setPage(page + 1);
    } else {
      return;
    }
  };

  const Notifications = async () => {
    setSpin(true);

    const actionItems = await serviceBASE.get(
      `/actionItems/${userData?._id}?size=10&page=${page}`
    );
    try {
      if (actionItems?.data.docs.actionItems_unread.length > 0 && page >= 1) {
        setPending([...pending, ...actionItems?.data.docs.actionItems_unread]);
      }
      if (actionItems?.data.docs.actionItems_read.length > 0 && page >= 1) {
        setIsReadPending([
          ...isReadPending,
          ...actionItems?.data.docs.actionItems_read,
        ]);
      }
    } catch (err) {
      console.log(err);
    }

    const newResponse = await serviceBASE.get(
      `/newNotifications/${userData?._id}/?size=10&page=${page}`
    );
    try {
      if (newResponse?.data.docs.notifications_unread.length > 0 && page >= 1)
        setData([...data, ...newResponse?.data.docs.notifications_unread]);
      if (newResponse?.data.docs.notifications_read.length > 0 && page >= 1) {
        setIsRead([...isRead, ...newResponse?.data.docs.notifications_read]);
      }
    } catch (err) {
      console.log(err);
    }

    setSpin(false);
  };

  // notificaciones read y unread

  const parsingData = (readData, data, isPending) => {
    let res = {
      0: [],
      1: [],
      2: [],
    };

    let total = [];

    if (readData?.length !== 0 && !isPending) {
      if (readData?.filter((i) => i.type !== 'Pending Item').length !== 0) {
        res[0].push(
          readData
            ?.filter((i) => i.type !== 'Pending Item')
            .map((item) => (
              <NotificationView
                read={item.is_read}
                type={item.type}
                body={item.body}
                key={item._id}
                id={item._id}
                date={item.date}
                title={item.title}
                miscData={item.miscData}
              />
            ))
        );
      }
    } else if (readData?.length !== 0 && isPending) {
      if (readData?.filter((i) => i.type === 'Pending Item').length !== 0) {
        res[0].push(
          readData
            ?.filter((i) => i.type === 'Pending Item')
            .map((item) => (
              <NotificationView
                pending={isPending}
                type={item.type}
                read={item.is_read}
                body={item.body}
                link={item.link}
                key={item._id}
                id={item._id}
                date={item.date}
                title={item.title}
                miscData={item.miscData}
              />
            ))
        );
      }
    }

    if (res[0].length !== 0) {
      res[1].push([
        ...res[0],
        data?.map((item) => (
          <NotificationView
            pending={isPending}
            type={item.type}
            body={item.body}
            key={item._id}
            id={item._id}
            link={item.link}
            date={item.date}
            title={item.title}
            miscData={item.miscData}
          />
        )),
      ]);
    } else if (res[0].length === 0) {
      if (data.length !== 0) {
        res[1].push(
          data?.map((item) => (
            <NotificationView
              type={item.type}
              body={item.body}
              link={item.link}
              key={item._id}
              id={item._id}
              date={item.date}
              title={item.title}
              miscData={item.miscData}
            />
          ))
        );
      }
    }
    res[2] = res[1][0];

    total = res[2] ? total.concat(res[2][0], res[2][1]) : null;

    return { res, total };
  };

  useEffect(() => {
    if (userData.length !== 0) {
      Notifications();
    }
  }, [page]);

  return (
    <div className="flex flex-col font-montserrat min-h-[90vh] text-white ">
      <div className="flex justify-center">
        <Tabs setNotiSelector={setNotiSelector} notiSelector={notiSelector}>
          <h3>Notifications</h3>
          <h3>Action Items</h3>
        </Tabs>
      </div>

      <div className="mx-auto rounded-2xl bg-bgprimary_ob animate-fade-in-down w-[700px] lg:w-[603px] md:w-[560px] flex flex-col pb-4 mb-8">
        {notiSelector.pendingItems &&
        parsingData(isReadPending, pending, true).res[1]?.length !== 0
          ? parsingData(isReadPending, pending, true)
              .total.sort(
                (a, b) => new Date(a.props.date) - new Date(b.props.date)
              )
              .reverse()
          : notiSelector.pendingItems &&
            parsingData(isReadPending, pending, true).res[1]?.length === 0 && (
              <span
                className="text-white text-base text-center mt-4
            "
              >
                No Action Items yet
              </span>
            )}

        {notiSelector.notifications &&
        parsingData(isRead, data, false).res[1]?.length !== 0
          ? parsingData(isRead, data, false)
              .total.sort(
                (a, b) => new Date(a.props.date) - new Date(b.props.date)
              )
              .reverse()
          : notiSelector.notifications &&
            parsingData(isRead, data, false).res[1]?.length === 0 && (
              <span className="text-white text-base text-center mt-4">
                No Alerts yet
              </span>
            )}
      </div>

      {spin && (
        <div className="text-center">
          {' '}
          <MiniSpinner />{' '}
        </div>
      )}
    </div>
  );
};

export default Notifications;
