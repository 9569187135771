import React, { useState, useEffect } from 'react';
import TextInputAc from '../../../../modal/TextInputAc';
import PhoneInput from 'react-phone-input-2';

import { serviceBASE } from '../../../../../config/axios';

import back from '../../../../../images/index/back-button.svg';
import documents from '../../../../../images/documents/documents.svg';
import add_docs from '../../../../../images/documents/add_docs.svg';

import Error from '../../../../../components/Messages/Error';
import Spinner from '../../../../../components/spinner/Spinner';
import PlaceAutoComplete from '../../../../../components/SearchAddres/PlaceAutoComplete';
import Documents from '../../../documents/Documents';

const IndAcc = ({
  userData,
  onChangeForm,
  data,
  setData,
  type,
  setShowForm,
  accData,
  isInvestor,
}) => {
  const {
    first_name,
    last_name,
    address,
    city,
    state,
    zip_code,
    country,
    phone,
    files,
  } = data;

  const [googleValue, setGoogleValue] = useState(null);
  const [typeDocs, setTypeDocs] = useState({
    passport: false,
    address: false,
    bank: false,
  });
  const [, setNewModal] = useState(false);
  const [docs, setDocs] = useState(false);
  const [sp, setSp] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [validate, setValidate] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    PassportOrGovernment: null,
    ProofOfAddress: null,
    BankStatement: null,
  });

  const [addDocs, setAddDocs] = useState();

  const setInvestorAccount = async (data) => {
    try {
      const response = await serviceBASE.patch(
        '/users/me/investor-information',
        data
      );
      if (response.status === 200) {
        setSp(false);
        setTimeout(() => {
          setErrMsg('');
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      setSp(false);
      if (!err.response) {
        setValidate(false);
        return setErrMsg('No server response');
      } else if (err.response.status === 400) {
        setValidate(false);
        return setErrMsg(`${err.response.data.message}`);
      } else if (err.response.status === 401) {
        setValidate(false);
        return setErrMsg('Unauthorized');
      } else {
        setValidate(false);
        setErrMsg('You must fill all the fields');
      }
    }
  };

  const coordinates = (element) => {
    return {
      y:
        window.scrollY +
        document.querySelector(`#${element}`)?.getBoundingClientRect().top,
      x:
        window.scrollX +
        document.querySelector(`#${element}`)?.getBoundingClientRect().left,
    };
  };

  useEffect(() => {
    setAddDocs({
      ...addDocs,
      first: coordinates('add_docs_1'),
      second: coordinates('add_docs_2'),
      third: coordinates('add_docs_3'),
    });
  }, []);

  useEffect(() => {
    if (googleValue !== null && type === 'usindividual') {
      setData({
        ...data,
        address: googleValue?.label,
        city: googleValue?.city,
        state: googleValue?.state,
        zip_code: googleValue?.postalCode,
      });
    } else if (googleValue !== null && type === 'foreignindividual') {
      setData({
        ...data,
        address: googleValue?.label,
        city: googleValue?.city,
        state: googleValue?.state,
        zip_code: googleValue?.postalCode,
        country: googleValue?.country,
      });
    }
  }, [googleValue]);

  useEffect(() => {
    setData({
      ...data,
      company_name: first_name + ' ' + last_name,
    });
  }, [first_name, last_name]);

  useEffect(() => {
    setData({
      ...data,
      type_investor: 'NaturalPerson',
      email: userData?.email,
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > 5 * 1024 * 1024) {
        setValidate(false);
        setErrMsg('The file sizes are too large');
        return;
      }
    }
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('file', files[i]);
    }
    Object.keys(selectedFile).forEach((tag) =>
      formData.append('type_files[]', tag)
    );
    formData.append('data', JSON.stringify(data));
    setSp(true);
    setValidate(true);
    setInvestorAccount(formData);
  };

  return (
    <div className="flex flex-col">
      {sp && <Spinner />}
      <div className="flex justify-center mb-12">
        <img
          src={back}
          alt={'back-button'}
          className="text-2xl self-start relative right-[21%] cursor-pointer"
          onClick={() => {
            docs ? setDocs(false) : setShowForm(false);
          }}
        />
        <div className="flex flex-col">
          <h1 className="self-center text-lg mr-4">Investment Account</h1>
          <span className="text-sm text-[#FFFFFF4D] self-center">
            {docs ? 'Documentation' : type}
          </span>
        </div>
      </div>
      {docs ? (
        <form
          className="flex flex-col space-y-12"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          <div className="flex justify-between">
            <div className="flex flex-col mt-12 md:w-[50%]">
              <img alt="docs" src={documents} width="40px" height="40px" />
              {typeDocs.passport ? (
                <Documents
                  title={`Passport`}
                  props={typeDocs}
                  setProps={setTypeDocs}
                  type={'passport'}
                  doc={accData?.files.find(
                    (f) => f.type_file === 'PassportOrGovernment'
                  )}
                  _id={accData?.user || userData?._id}
                  isInvestor={!isInvestor ? isInvestor : true}
                />
              ) : null}
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'foreignindividual'
                  ? 'Copy of Passport'
                  : (type === 'usentity' || type === 'foreignentity') &&
                    'Copy of Passport Authorized Individual'}
              </span>
              {/* {selectedFile.PassportOrGovernment && (
                <span className="mt-1 text-sm flex ">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.PassportOrGovernment}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.PassportOrGovernment && (
                <span className="self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[35px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.first.x}px] top-[${addDocs?.first.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.first.x}px] top-[${addDocs?.first.y}px]`
                  }
                  onChange={(e) => {
                    e.target.files[0].metadata = 'PassportOrGovernment';

                    setData((prevState) => ({
                      ...data,
                      files: prevState.files.map((item, index) => {
                        if (index === 0) {
                          return e.target.files[0];
                        } else {
                          return item;
                        }
                      }),
                    }));

                    setSelectedFile({
                      ...selectedFile,
                      PassportOrGovernment: e.target.files[0],
                    });
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[40px]"
                  id="add_docs_1"
                />
              </div>
              <button
                type={'button'}
                onClick={() => {
                  setTypeDocs({
                    ...typeDocs,
                    passport: true,
                  });
                  setNewModal(true);
                }}
                className="bg-bggray_ob w-fit hover:opacity-60 hover:drop-shadow-lg px-3 py-1 h-fit self-center rounded-2xl ml-6"
              >
                Last Document
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col my-8 md:w-[50%]">
              <img alt="docs" src={documents} width="40px" height="40px" />
              {typeDocs.address ? (
                <Documents
                  title={`Address`}
                  props={typeDocs}
                  setProps={setTypeDocs}
                  type={'address'}
                  doc={accData?.files.find(
                    (f) => f.type_file === 'ProofOfAddress'
                  )}
                  _id={accData?.user || userData?._id}
                  isInvestor={!isInvestor ? isInvestor : true}
                />
              ) : null}
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'foreignindividual'
                  ? 'Proof of Address'
                  : (type === 'usentity' || type === 'foreignentity') &&
                    'Proof of Address of Authorized Individual'}
              </span>
              {/* {selectedFile.ProofOfAddress && (
                <span className="mt-1 text-sm flex">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.ProofOfAddress}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.ProofOfAddress && (
                <span className=" self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[35px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.second.x}px] top-[${addDocs?.second.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.second.x}px] top-[${addDocs?.second.y}px]`
                  }
                  onChange={(e) => {
                    e.target.files[0].metadata = 'ProofOfAddress';

                    setSelectedFile({
                      ...selectedFile,
                      ProofOfAddress: e.target.files[0],
                    });
                    setData((prevState) => ({
                      ...data,
                      files: prevState.files.map((item, index) => {
                        if (index === 1) {
                          return e.target.files[0];
                        } else {
                          return item;
                        }
                      }),
                    }));
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[35px]"
                  id="add_docs_2"
                />
              </div>
              <button
                type={'button'}
                onClick={() => {
                  setTypeDocs({
                    ...typeDocs,
                    address: true,
                  });
                  setNewModal(true);
                }}
                className="bg-bggray_ob w-fit hover:opacity-60 hover:drop-shadow-lg px-3 py-1 h-fit self-center rounded-2xl ml-6"
              >
                Last Document
              </button>
            </div>
          </div>
          <div className="flex justify-between">
            <div className="flex flex-col mt-2">
              <img alt="docs" src={documents} width="40px" height="40px" />
              {typeDocs.bank ? (
                <Documents
                  title={type === 'usindividual' ? `W-9 Form` : 'W-8 Ben Form'}
                  props={typeDocs}
                  setProps={setTypeDocs}
                  type={'bank'}
                  doc={accData.files.find(
                    (f) => f.type_file === 'BankStatement'
                  )}
                  _id={accData?.user || userData?._id}
                  isInvestor={!isInvestor ? isInvestor : true}
                />
              ) : null}
              <span className="mt-1 text-sm">
                {type === 'usindividual' || type === 'usentity'
                  ? 'W-9 Form'
                  : type === 'foreignindividual' ? 'W-8 BEN Form' : 'W-8 BEN-E Form'}
              </span>
              {/* {selectedFile.BankStatement && (
                <span className="mt-1 text-sm flex">
                  Expiration Date:
                  <ExpirateDate
                    setData={setData}
                    data={data}
                    file={selectedFile.BankStatement}
                  />
                </span>
              )} */}
            </div>
            <div className="flex">
              {selectedFile.BankStatement && (
                <span className="self-center text-sm text-[#FFFFFF4D]">
                  Uploaded
                </span>
              )}
              <div className="w-[35px] self-center">
                <input
                  type={'file'}
                  accept={'image/*'}
                  className={
                    type === 'usentity' || type === 'foreignentity'
                      ? `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.third.x}px] top-[${addDocs?.third.y}px]`
                      : `w-8 file:cursor-pointer cursor-pointer opacity-0 file:rounded-2xl h-[35px] absolute left-[${addDocs?.third.x}px] top-[${addDocs?.third.y}px]`
                  }
                  onChange={(e) => {
                    e.target.files[0].metadata = 'BankStatement';
                    setSelectedFile({
                      ...selectedFile,
                      BankStatement: e.target.files[0],
                    });
                    setData((prevState) => ({
                      ...data,
                      files: prevState.files.map((item, index) => {
                        if (index === 2) {
                          return e.target.files[0];
                        } else {
                          return item;
                        }
                      }),
                    }));
                  }}
                />
                <img
                  alt="add_docs"
                  src={add_docs}
                  className="w-[35px]"
                  id="add_docs_3"
                />
              </div>
              <button
                type={'button'}
                onClick={() => {
                  setTypeDocs({
                    ...typeDocs,
                    bank: true,
                  });
                  setNewModal(true);
                }}
                className="bg-bggray_ob w-fit hover:opacity-60 hover:drop-shadow-lg px-3 py-1 h-fit self-center rounded-2xl ml-6"
              >
                Last Document
              </button>
            </div>
          </div>
          <button
            type="submit"
            className="bg-bggray_ob self-center w-[200px] py-2 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Save Changes and Exit
          </button>
          {!validate && (
            <div className="mx-auto">
              <Error message={errMsg} />
            </div>
          )}
        </form>
      ) : (
        <>
          <div className="flex flex-col space-y-6">
            <div className="flex flex-col mt-6 w-[100%]">
              <TextInputAc
                name={'first_name'}
                value={first_name}
                onChangeForm={onChangeForm}
                type={'text'}
              />
              <label className="text-xs mt-2" htmlFor="totalname">
                NAME
              </label>
            </div>
            <div className="flex flex-col mt-2 w-[100%]">
              <TextInputAc
                name={'last_name'}
                value={last_name}
                onChangeForm={onChangeForm}
                type={'text'}
              />
              <label className="text-xs mt-2" htmlFor="lastname">
                LAST NAME
              </label>
            </div>
            <div className="flex flex-col mt-2 w-[100%]">
              <div className="border-b-2 pb-1 bg-transparent">
                <PlaceAutoComplete
                  value={googleValue}
                  defaultValue={address}
                  setValue={setGoogleValue}
                />
              </div>
              <label className="text-xs mt-2" htmlFor="address">
                ADDRESS
              </label>
            </div>
            <div className="flex flex-col mt-2 w-[100%]">
              <TextInputAc
                name={'city'}
                value={city}
                onChangeForm={onChangeForm}
                type={'text'}
              />
              <label className="text-xs mt-2" htmlFor="city">
                CITY
              </label>
            </div>
            {type === 'foreignindividual' && (
              <div className="flex flex-col mt-2">
                <TextInputAc
                  name={'state'}
                  value={state}
                  onChangeForm={onChangeForm}
                  type={'text'}
                />
                <label className="text-xs mt-2" htmlFor="state">
                  STATE OF PROVINCE
                </label>
              </div>
            )}
            <div className="flex flex-col mt-2 w-[100%]">
              <TextInputAc
                name={'zip_code'}
                value={zip_code}
                onChangeForm={onChangeForm}
                type={'text'}
              />
              <label className="text-xs mt-2" htmlFor="zip_code">
                ZIP CODE
              </label>
            </div>
            {type === 'foreignindividual' && (
              <div className="flex flex-col mt-2">
                <TextInputAc
                  name={'country'}
                  value={country}
                  onChangeForm={onChangeForm}
                  type={'text'}
                />
                <label className="text-xs mt-2" htmlFor="country">
                  COUNTRY
                </label>
              </div>
            )}
            <div className="flex flex-col my-2 w-[100%]">
              <PhoneInput
                country={'us'}
                regions={[
                  'north-america',
                  'south-america',
                  'central-america',
                  'carribean',
                ]}
                preferredCountries={['us', 'ar', 'mx', 'ca']}
                inputProps={{
                  name: 'phone',
                  placeholder: phone,
                  value: `+${phone}`,
                  maxLength: 15,
                }}
                enableLongNumbers={true}
                onChange={(phoneNumber) =>
                  setData({
                    ...data,
                    phone: phoneNumber,
                  })
                }
                inputStyle={{
                  width: '100%',
                  backgroundColor: 'transparent',
                  borderLeftWidth: '0px',
                  borderTopWidth: '0px',
                  borderRightWidth: '0px',
                  borderBottomWidth: '2px',
                  borderRadius: '0',
                }}
                dropdownStyle={{
                  color: 'black',
                }}
                buttonStyle={{
                  backgroundColor: 'transparent',
                  borderWidth: '0',
                }}
              />
              <label className="text-xs mt-1" htmlFor="phone">
                PHONE
              </label>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <button
              type="button"
              onClick={() => setDocs(true)}
              className="bg-bggray_ob w-[100px] py-1 rounded-2xl my-8 hover:bg-gray-500 hover:drop-shadow-xl"
            >
              Next
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default IndAcc;
