import React, { useState } from 'react';

import PersonalForm from './forms/PersonalForm';
import RegisterForm from './forms/RegisterForm';
import TypeAccount from './TypeAccount';

import logo from '../../../images/index/logo.svg';
import ContainerLog from '../../../components/utils/ContainerLog';

const Register = ({ token }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [isPersonal, setIsPersonal] = useState(false);
  const [type, setType] = useState({
    entity: false,
    individual: false,
  });

  const [newUser, setNewUser] = useState({
    first_name: '',
    email: '',
    password: '',
    confirmPw: '',
    last_name: '',
    address: '',
    country: '',
    phone: 0,
    type_investor: 'NaturalPerson',
    accepted_terms_and_conditions: false,
  });

  return (
    <>
      {!token ? (
        <div className="flex flex-col items-center">
          {isPersonal && (
            <span className="text-white font-montserrat h-auto mt-8 text-lg">
              Welcome to{' '}
            </span>
          )}
          <img
            alt="logo"
            src={logo}
            className="w-[200px] mt-6 mb-2 self-center"
          />
          <div className="w-[80%] text-white font-montserrat max-w-lg p-5 relative px-11 mt-12 rounded-xl shadow-lg bg-bgprimary_ob mb-8">
            {!isRegister ? (
              <RegisterForm
                isRegister={isRegister}
                setIsRegister={setIsRegister}
                newUser={newUser}
                setNewUser={setNewUser}
              />
            ) : !isPersonal ? (
              <PersonalForm
                setIsPersonal={setIsPersonal}
                newUser={newUser}
                setNewUser={setNewUser}
              />
            ) : // ) : (
            //   <TypeAccount
            //     setIsPersonal={setIsPersonal}
            //     type={type}
            //     setType={setType}
            //     newUser={newUser}
            //     setNewUser={setNewUser}
            //   />
            // )}
            null}
          </div>
        </div>
      ) : (
        <ContainerLog />
      )}
    </>
  );
};

export default Register;
