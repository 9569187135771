import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from 'react-router-dom';

//----------- Login -----------//
import Register from './views/login/views/Register';
import Signin from './views/login/views/Signin';
import Layout from './views/login/Layout';
import Welcome from './views/login/views/Welcome';

//----------- Home -----------//
import Cardclick from './views/home/item/Cardclick';
import Index from './views/layout/Index';
import Main from './views/home/Main';

//----------- Overview -----------//
import InfoHolding from './views/overview/holdingview/InfoHolding';
import Overview from './views/overview/Overview';

//----------- Profile -----------//
import Profile from './views/profile/Profile';
import NewInvestorAcc from './views/profile/investorAccount/NewInvestorAcc';
import RegisterInvestor from './views/profile/investorAccount/RegisterInvestor';
import TypeEntitys from './views/profile/investorAccount/TypeEntitys';
import InvestorDocuments from './views/profile/investorAccount/InvestorDocuments';

//----------- Notifications -----------//
import Notifications from './views/notifications/Notifications';
import NotificationsProvider from './context/notifications/NotificationsContext';

//----------- Auth/Error -----------//
import tokenAuth from './config/tokenAuth';
import AuthState from './config/auth/authState';
import PrivateRoute from './components/routes/PrivateRoute';
import ErrorPage from './components/ErrorPage/ErrorPage';
import InvestorAccProvider from './context/investoracc/InvestorAccContext';

function App() {
  const token = localStorage.getItem('jwt');
  if (token) {
    tokenAuth(token);
  }

  function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  }

  function checkAppInstalled() {
    const isMobile = /Mobile|Android/.test(navigator.userAgent);
    if (isMobile) {
      const appScheme = "com.noctua.onebric.app://";
      const appLink = document.createElement("a");
      appLink.href = appScheme;
      document.body.appendChild(appLink);
      const appInstalled = Boolean(appLink.click());
      document.body.removeChild(appLink);
      if (appInstalled) {
        console.log("La aplicación está instalada");
      } else {
        console.log("La aplicación no está instalada");
      }
    }
  };

  useEffect(() => {
    checkAppInstalled();
  }, []);

  return (
    <NotificationsProvider>
      <AuthState>
        <InvestorAccProvider>
          <Router>
            <Index>
              <ScrollToTop />
              <Routes>
                <Route
                  path="/signup/register"
                  element={<Register token={token} />}
                />
                <Route
                  path="/signup/login"
                  element={<Signin token={token} />}
                />
                <Route path="/signup" element={<Layout />} />

                <Route element={<PrivateRoute />}>
                  <Route path="/" element={<Main />} />
                  <Route path="/welcome" element={<Welcome />} />
                  <Route path="item/:cardId" element={<Cardclick />} />
                  <Route path="/overview" element={<Overview />} />
                  <Route
                    path="/overview/holding/:currentId"
                    element={<InfoHolding />}
                  />
                  <Route path="/profile" element={<Profile />} />
                  <Route
                    path="/profile/useraccount"
                    element={<NewInvestorAcc />}
                  />
                  <Route
                    path="/profile/useraccount/register"
                    element={<RegisterInvestor />}
                  />
                  <Route
                    path="/profile/useraccount/register/:type"
                    element={<TypeEntitys />}
                  />
                  <Route
                    path="/profile/useraccount/register/:type/documents"
                    element={<InvestorDocuments />}
                  />
                  <Route path="/notifications" element={<Notifications />} />
                </Route>

                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </Index>
          </Router>
        </InvestorAccProvider>
      </AuthState>
    </NotificationsProvider>
  );
}

export default App;
