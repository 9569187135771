import { proxy } from 'valtio';

export const state = proxy({ isLoading: false });

export const toggleLoading = () => {
  if (state.isLoading) {
    state.isLoading = false;
  } else {
    state.isLoading = true;
  }
};
