import React, { useState } from 'react';

import SignInForm from './forms/SignInForm';
import RecoverForm from './forms/RecoverForm';
import ContainerLog from '../../../components/utils/ContainerLog';

import logo from '../../../images/index/logo.svg';

const Signin = ({ token }) => {
  const [recovPw, setRecovPw] = useState(false);

  return (
    <>
      {!token ? (
        <div className="flex flex-col items-center">
          <img alt="logo" src={logo} className="w-[200px] mt-14" />
          <div className="w-[80%] text-white font-montserrat max-w-lg p-5 relative px-11 mt-8 rounded-xl shadow-lg bg-bgprimary_ob">
            <div className="flex flex-col">
              {!recovPw ? (
                <SignInForm setRecovPw={setRecovPw} />
              ) : (
                <RecoverForm setRecovPw={setRecovPw} />
              )}
            </div>
          </div>
        </div>
      ) : (
        <ContainerLog />
      )}
    </>
  );
};

export default Signin;
