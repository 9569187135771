import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { serviceBASE } from '../../../config/axios';
import { abbreviateNumber, abbreviateDate } from '../../../store/utils';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router';

import Mapa from '../../../components/Map/Map';
import Carousel from '../../../components/Carousel/Carousel';
import Spinner from '../../../components/spinner/Spinner';
import back from '../../../images/index/back-button.svg';

import './Cardclick.css';
import InvestForm from './form/InvestForm';

const Cardclick = () => {
  const { cardId } = useParams();

  let navigate = useNavigate();
  let location = useLocation();

  const [newInvest, setNewInvest] = useState();
  const [sp, setSp] = useState(true);
  const [form, setForm] = useState(false);
  const [data, setData] = useState([]);
  const [coord, setCoord] = useState({ lat: null, lng: null });

  useEffect(() => {
    const consulta = async () => {
      const response = await serviceBASE.get(`/projects/${cardId}`);
      const response2 = await serviceBASE.get(
        `/users/project-investment/${cardId}`
      );
      setNewInvest(response2?.data);
      setData(response?.data);
      setCoord({
        lat: response?.data.address.location.coordinates[1],
        lng: response?.data.address.location.coordinates[0],
      });
      setSp(false);
    };
    consulta();
  }, [cardId]);

  const invId = newInvest?.investment?._id;

  const status = data?.status;

  const date_mat = new Date(data?.maturity);

  const date_dead = new Date(data?.deadline);

  return (
    <>
      {!form ? (
        <>
          {sp && <Spinner />}
          <div className="flex flex-col items-center min-h-[90vh] font-montserrat">
            <div className="text-white mt-4 w-[815px] lg:w-[650px] md:w-[550px] text-3xl">
              <img
                alt="back"
                src={back}
                className="cursor-pointer"
                onClick={() => {
                  if (location.state !== null) {
                    window.location.reload(navigate('/notifications'));
                  } else navigate(-1);
                }}
              />
            </div>
            <div className="flex justify-evenly text-white text-md lg:text-sm">
              <span className="text-orange_ob font-medium"> {data?.type} </span>
              <span className="ml-11 mr-11"> {data?.name} </span>
              <span>
                {`${data?.address?.street_number || ` `} 
                ${data?.address?.street?.concat(',') || `Loading`}
                ${data?.address?.locality?.concat(',') || ` `} 
                ${data?.address?.province || ` `}`}
              </span>
            </div>

            <div className="flex flex-col pt-8 w-[815px] lg:w-[650px] md:w-[550px] items-center mb-8">
              <Carousel dataSlider={data?.images} cardId={cardId} />
              <div className="flex flex-col items-center text-white w-full rounded-b-2xl bg-bgprimary_ob">
                <div className="flex justify-between text-xs md:text-2xs pt-4 px-8 w-[737px] md:w-[546px] lg:w-[630px]">
                  <span className="w-[90px]">
                    PROYECT SIZE {`$${abbreviateNumber(data?.target)} `}
                  </span>
                  <span className="w-[100px] mx-2">
                    TARGET NET IRR {`${data?.target_irr || '0'}%`}
                  </span>
                  <span className="w-[95px]">
                    MATURITY <br />
                    {abbreviateDate(date_dead)}
                  </span>
                  <span className="w-[80px] mx-2 text-orange_ob">
                    DEADLINE <br />
                    {abbreviateDate(date_mat)}
                  </span>
                  <span className="w-[120px] text-orange_ob">
                    MIN. INVESTMENT {`$${abbreviateNumber(data?.min_invested)}`}
                  </span>
                </div>
                {status === 0 && newInvest?.status === 'Not Investment' ? (
                  <div className="flex flex-col items-center mt-6">
                    <button
                      className="font-montserrat font-medium rounded-2xl bg-orange_ob w-[140px] h-[40px] hover:shadow-xl"
                      onClick={() => setForm(true)}
                    >
                      {' '}
                      INVEST NOW{' '}
                    </button>
                  </div>
                ) : newInvest?.status === 'Pending' ? (
                  <div className="flex flex-col items-center mt-6">
                    <button className="font-montserrat font-medium rounded-2xl bg-bgnewgray_ob w-[140px] h-[40px]">
                      {' '}
                      PENDING ...{' '}
                    </button>
                  </div>
                ) : newInvest?.status === 'Approved' ? (
                  <div className="flex flex-col items-center mt-6">
                    <button
                      className="font-montserrat font-medium rounded-2xl bg-orange_ob w-[180px] h-[40px]"
                      onClick={() => {
                        navigate(`/overview/holding/${invId}`);
                      }}
                    >
                      {' '}
                      VIEW INVESTMENT{' '}
                    </button>
                  </div>
                ) : null}
                <div className="flex flex-col">
                  <div className="mt-4 w-[737px] lg:w-[630px] md:w-[546px] mx-auto">
                    {data?.description !== '' ? (
                      <div
                        id="--description_container"
                        className="ml-8 mr-6 font-light text-sm mb-4"
                        dangerouslySetInnerHTML={{
                          __html: `${data?.description} `,
                        }}
                      ></div>
                    ) : (
                      <div
                        id="--description_container_summary"
                        className="ml-8 mr-6 font-light text-sm mb-4"
                      >
                        {data?.summary}
                      </div>
                    )}

                    <div className="ml-8 mr-6 mt-8 mb-11">
                      <Mapa coord={coord} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <InvestForm setForm={setForm} data={data} />
      )}
    </>
  );
};

export default Cardclick;
