import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InvestorAccContext } from '../../../context/investoracc/InvestorAccContext';
import { NotificationsContext } from '../../../context/notifications/NotificationsContext';

import Layout from './Layout';
import UsIndividual from './forms/UsIndividualForm';

import UsEntity from './forms/UsEntityForm';
import ForeignIndividual from './forms/ForeignIndividualForm';
import ForeignEntity from './forms/ForeignEntityForm';
import ErrorPage from '../../../components/ErrorPage/ErrorPage';

const TypeEntitys = () => {
  const { type } = useParams();

  const { setType } = useContext(InvestorAccContext);

  const { userData } = useContext(NotificationsContext);

  const { entity_information, personal_information } = userData;

  const [error, setError] = useState('');

  const [message, setMessage] = useState('');

  const [personalInfo, setPersonalInfo] = useState({
    first_name: '',
    last_name: '',
    email: '',
    location: {
      country: '',
      address: '',
      city: '',
      state: '',
      zip_code: '',
    },
    phone: 0,
    type_investor: '',
    type_investor_account: type,
    accredited_investor: false,
    authorized_represent_entity: false,
  });

  const { location } = personalInfo;

  const onChangeForm = (e) => {
    if (
      e.target.name === 'country' ||
      e.target.name === 'address' ||
      e.target.name === 'city' ||
      e.target.name === 'state' ||
      e.target.name === 'zip_code' ||
      e.target.name === 'state_province'
    ) {
      setPersonalInfo({
        ...personalInfo,
        location: {
          ...location,
          [e.target.name]: e.target.value,
        },
      });
    } else
      setPersonalInfo({
        ...personalInfo,
        [e.target.name]: e.target.value,
      });
  };

  setType(type);

  useEffect(() => {
    if (type === 'usindividual' || type === 'foreignindividual') {
      setMessage('Individual Investment Account Information');
      setPersonalInfo({
        ...personalInfo,
        first_name:
          entity_information?.first_name || personal_information?.first_name,
        last_name:
          entity_information?.last_name || personal_information?.last_name,
        email: userData.email,
        phone: entity_information?.phone || personal_information?.phone,
      });
    } else {
      setMessage('Entity Investment Account Information');
    }
  }, [type]);

  return (
    <Layout message={message} personalInfo={personalInfo}>
      {type === 'usindividual' ? (
        <UsIndividual
          type={type}
          userData={userData}
          error={error}
          setError={setError}
          personalInfo={personalInfo}
          setPersonalInfo={setPersonalInfo}
          onChangeForm={onChangeForm}
        />
      ) : type === 'usentity' ? (
        <UsEntity
          type={type}
          error={error}
          personalInfo={personalInfo}
          setError={setError}
          setPersonalInfo={setPersonalInfo}
          onChangeForm={onChangeForm}
        />
      ) : type === 'foreignindividual' ? (
        <ForeignIndividual
          type={type}
          userData={userData}
          error={error}
          personalInfo={personalInfo}
          setError={setError}
          setPersonalInfo={setPersonalInfo}
          onChangeForm={onChangeForm}
        />
      ) : type === 'foreignentity' ? (
        <ForeignEntity
          type={type}
          error={error}
          personalInfo={personalInfo}
          setError={setError}
          setPersonalInfo={setPersonalInfo}
          onChangeForm={onChangeForm}
        />
      ) : (
        (type !== 'usindividual' ||
          type !== 'foreignentity' ||
          type !== 'usentity' ||
          type !== 'foreignindividual') && (
          <div className="mt-12">
            <ErrorPage />
          </div>
        )
      )}
    </Layout>
  );
};

export default TypeEntitys;
