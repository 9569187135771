import React, { useMemo } from "react";
import { investArr, formatInvestor } from "../../../../store/utils";

import OvwMsg from "../../../../components/Messages/OvwMsg";

const InvestorDoc = ({ data }) => {
  const docInfo = data?.documents?.investment_docs;

  const formattedDates = investArr(docInfo);

  const formattedInvestor = useMemo(
    () => formatInvestor(formattedDates),
    [formattedDates]
  );

  return (
    <>
      {docInfo?.length === 0 || docInfo?.length === undefined ? (
        <OvwMsg
          message="No info for this 
        section yet."
        />
      ) : (
        formattedInvestor
      )}
    </>
  );
};

export default InvestorDoc;
