import React, { useState, useEffect } from 'react';
import { serviceBASE } from '../../config/axios';
import { overviewArr } from '../../store/utils.js';
import { useNavigate } from 'react-router';

import logo from '../../images/index/logo.svg';
import Spinner from '../../components/spinner/Spinner';
import MyHoldings from './MyHoldings';
import StChartQuarter from '../../components/Charts/StChartQuarter';
import StChartMonthly from '../../components/Charts/StChartMonthly';
import DonutChart from '../../components/Charts/doughnut/DonutChart';

const Overview = () => {
  const [data, setData] = useState([]);
  const [loaded, isLoaded] = useState(false);
  const [sp, setSp] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const consulta = async () => {
      const response = await serviceBASE.get(`/users/investments/dashboard`);
      setData(response.data.my_dashboards);
      isLoaded(true);
      setSp(false);
    };
    consulta();
  }, []);

  const sum = overviewArr(data)[2].reduce((partialSum, a) => partialSum + a, 0);

  const totalQuarter = overviewArr(data)[3].reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  const newData = (project) => {
    let res = { total: [] };
    project.forEach((element) => {
      res.total.push({
        am: element?.invested_capital,
        date: new Date(element?.investment_date).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric',
        }),
        name: element?.project_name,
      });
    });
    return res;
  };

  const projectNames = [];

  newData(overviewArr(data)[1])
    .total.sort((a, b) => a.am - b.am)
    .forEach((e) => projectNames.push(e.name));

  return (
    <>
      {sp && <Spinner />}
      <div className="min-h-screen font-montserrat text-white mx-auto">
        <h2 className="text-center mt-11 font-bold">Overview</h2>
        {data.length === 0 ? (
          <div className="mt-8 flex flex-col items-center justify-center">
            <span className="text-md">
              You need to invest at least once to see the details of it.
            </span>
            <br />
            <span className="text-md text-center w-[50%]">
              If you don't have any Investment Account, you need to add at least
              one Investment Account to be able to participate in opportunities.
              Please click the button below to create an Investment Account.
            </span>
            <button
              type="button"
              onClick={() => navigate('/profile/useraccount/register')}
              className="bg-bggray_ob w-[25%] mt-12 py-1 rounded-2xl hover:bg-gray-500 hover:drop-shadow-xl"
            >
              + Add New Investment Account
            </button>
            <img alt="logo" src={logo} className="w-[180px] h-[100px] mt-6" />
          </div>
        ) : (
          <div className="flex flex-col bg-bgprimary_ob m-auto mt-9 rounded-2xl lg:w-[603px] w-[815px]">
            <div className="flex">
              <div className="flex flex-col w-[50%] items-center">
                <span className="text-base mt-4">Quarter</span>
                <StChartQuarter
                  sum={sum}
                  data={data}
                  loaded={loaded}
                  totalQuarter={totalQuarter}
                  currentHoldings={overviewArr(data)[1]}
                />
              </div>
              <div className="flex flex-col  w-[50%] items-center">
                <span className="text-base mt-4">Monthly</span>
                <StChartMonthly
                  sum={sum}
                  data={data}
                  currentHoldings={overviewArr(data)[1]}
                  loaded={loaded}
                />
              </div>
            </div>
            <div className="flex flex-col items-center">
              <ul className="flex w-[66%] justify-evenly ml-[4rem] list-disc mb-4 align-baseline lg:justify-between">
                <li className="marker:text-contenido marker:text-3xl mt-4 text-base">
                  <span className="relative bottom-[3px] right-[4px]">
                    Portfolio Loan
                  </span>
                </li>
                <li className="marker:text-orange_ob  marker:text-3xl text-base mt-4">
                  <span className="relative bottom-[3px] right-[4px]">
                    Equity Investment
                  </span>
                </li>
              </ul>
            </div>
            <div className="mt-4 w-[300px] lg:w-[290px] lg:mr-[-2rem] self-center">
              <DonutChart
                newData={newData(overviewArr(data)[1]).total.sort(
                  (a, b) => a.am - b.am
                )}
                projectNames={projectNames}
                loaded={loaded}
              />
            </div>
            <div className="flex flex-col items-center mb-9">
              <span className="text-lg">
                Total Investment: ${new Intl.NumberFormat().format(sum)}
              </span>
            </div>
          </div>
        )}
        {data?.length === 0 ? null : (
          <>
            <h2 className="text-center mt-11 font-bold mb-4">My Holdings</h2>
            <div className="flex m-auto my-8 lg:flex-col">
              <MyHoldings
                data={overviewArr(data)[1]}
                title={'CURRENT HOLDINGS'}
              />
              <MyHoldings data={overviewArr(data)[0]} title={'PAST HOLDINGS'} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Overview;
