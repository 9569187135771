import { Marker, TileLayer, Map } from 'react-leaflet';

const Mapa = ({ coord }) => {
  return (
    <>
      {coord.lat && coord.lng && (
        <div className="sticky">
          <Map
            style={{ height: '274px', position: 'sticky' }}
            center={[coord.lat, coord.lng]}
            zoom={15}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[coord.lat, coord.lng]} />
          </Map>
        </div>
      )}
    </>
  );
};

export default Mapa;
