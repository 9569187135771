import React, { useContext, useState } from 'react';
import { serviceBASE } from '../../../config/axios';

import TextInputPw from '../../../views/modal/TextInputPw';
import Modal from '../../../components/Modal/Modal';
import Error from '../../../components/Messages/Error';
import AuthContext from '../../../config/auth/authContext';
import Message from '../../../components/Messages/Message';

const FormPw = ({ setIsModalpw, setPw }) => {
  const { errMsg, setErrMsg } = useContext(AuthContext);

  const [success, setSuccess] = useState(false);
  const [change, setChange] = useState({});

  const onChangeForm = (e) => {
    setChange({
      ...change,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = async () => {
    try {
      let response = serviceBASE.patch('/users/me/change-password', {
        oldPassword: change?.ct_pw,
        newPassword: change?.new_pw,
      });

      if ((await response).status === 200) {
        setSuccess(true);
      }
      return response;
    } catch (err) {
      if (!err.response) {
        return setErrMsg('No server response');
      } else if (err.response.status === 400) {
        return setErrMsg(`${err.response.data.message}`);
      } else if (err.response.status === 500) {
        return setErrMsg(`${err.response.data.info}`);
      }
    }
  };

  const handleSubmitpw = (e) => {
    e.preventDefault();
    if (change?.new_pw !== change?.cf_new_pw) {
      setErrMsg('Passwords must match');
    } else {
      handleChange();
      setErrMsg('');
    }
    setSuccess(false);
  };

  return (
    <Modal>
      <span className="text-base my-4">Changes Password</span>
      <form onSubmit={handleSubmitpw}>
        <div className="flex flex-col mt-4">
          <div className="flex border-photo_ob border-b-[2px]">
            <TextInputPw name={'ct_pw'} onChangeForm={onChangeForm} />
          </div>
          <label className="text-xs font-light mt-1" htmlFor="ct_pw">
            {' '}
            ENTER YOUR CURRENT PASSWORD{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <div className="flex border-photo_ob border-b-[2px]">
            <TextInputPw name={'new_pw'} onChangeForm={onChangeForm} />
          </div>
          <label className="text-xs font-light mt-1" htmlFor="new_pw">
            {' '}
            ENTER YOUR NEW PASSWORD{' '}
          </label>
        </div>
        <div className="flex flex-col mt-4">
          <div className="flex border-photo_ob border-b-[2px]">
            <TextInputPw name={'cf_new_pw'} onChangeForm={onChangeForm} />
          </div>
          <label className="text-xs font-light mt-1" htmlFor="conf_new_pw">
            {' '}
            CONFIRM YOUR NEW PASSWORD{' '}
          </label>
        </div>
        {success ? <Message succesfull="Password changed" /> : null}
        {errMsg ? <Error message={errMsg} /> : null}

        <div className="flex">
          <button
            type="submit"
            className="bg-button_ob hover:bg-blue-400 w-[40%] h-[40%] mx-auto text-white text-sm font-regular py-2 px-4 rounded-2xl my-10"
          >
            Save
          </button>
          <button
            type="button"
            className="bg-orange_ob hover:bg-bgletters_ob w-[40%] h-[40%] mx-auto text-white text-sm font-regular py-2 px-4 rounded-2xl my-10"
            onClick={() => {
              setIsModalpw(false);
              setErrMsg('');
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default FormPw;
