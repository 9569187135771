import {
  serviceAUTH,
  serviceBASE,
  serviceLOCAL,
  serviceSTAGING,
} from './axios';

const tokenAuth = (token) => {
  if (token) {
    serviceBASE.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    serviceAUTH.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    serviceLOCAL.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    serviceSTAGING.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete serviceBASE.defaults.headers.common['Authorization'];
    delete serviceAUTH.defaults.headers.common['Authorization'];
    delete serviceLOCAL.defaults.headers.common['Authorization'];
    delete serviceSTAGING.defaults.headers.common['Authorization'];
  }
};

export default tokenAuth;
