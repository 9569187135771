import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { toggleLoading, state } from '../../../../store/store';

import back from '../../../../images/index/back-button.svg';
import TextInputAc from '../../../modal/TextInputAc';
import TextInputPw from '../../../modal/TextInputPw';
import AuthContext from '../../../../config/auth/authContext';
import Error from '../../../../components/Messages/Error';
import Spinner from '../../../../components/spinner/Spinner';

const SignInForm = ({ setRecovPw }) => {
  const { LogIn, validate, errMsg, setErrMsg } = useContext(AuthContext);

  let navigate = useNavigate();

  const [credential, setCredential] = useState({
    username: '',
    password: '',
  });
  const [sp, setSp] = useState(false);

  const { username, password } = credential;

  const handleClick = (e) => {
    setRecovPw(true);
  };

  const onChangeForm = (e) => {
    setCredential({
      ...credential,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSp(true);
    //auth
    LogIn({ username, password });
  };

  useEffect(() => {
    if (validate) {
      const timer = setTimeout(() => {
        return window.location.reload(navigate('/'));
      }, 1500);
      return () => {
        clearTimeout(timer);
      };
    } else setSp(false);
  }, [validate, navigate]);

  return (
    <>
      {sp && <Spinner />}
      <form onSubmit={handleSubmit}>
        <img
          alt="back"
          src={back}
          className="self-start mb-4 cursor-pointer"
          onClick={() => {
            navigate('/signup');
            setErrMsg('');
          }}
        />

        <div className="flex flex-col">
          <TextInputAc
            name={'username'}
            type={'email'}
            value={username}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs mt-1" htmlFor="email">
            EMAIL
          </label>
        </div>
        <div className="flex border-photo_ob border-b-[2px] mt-8">
          <TextInputPw
            name={'password'}
            value={password}
            onChangeForm={onChangeForm}
          />
        </div>
        <label className="text-xs mt-1" htmlFor="pw">
          PASSWORD
        </label>
        {/* {success ? <Message succesfull={success} /> : null} */}
        {errMsg ? <Error message={errMsg} /> : null}
        <div className="flex flex-col items-center">
          <a
            href="#?"
            className="text-base mt-11 cursor-pointer"
            onClick={handleClick}
          >
            Forgot your password?
          </a>
          <button
            type="submit"
            className="bg-bgnewgray_ob w-[25%] py-1 rounded-2xl my-8"
          >
            Sign In
          </button>
        </div>
      </form>
    </>
  );
};

export default SignInForm;
