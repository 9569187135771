import React, { useMemo } from 'react';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  ArcElement,
} from 'chart.js';

import { colorPalette } from '../../../store/utils';

import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement
);

const DonutChart = ({ projectNames, loaded, newData }) => {
  const options = {
    fill: true,
    animation: true,
    responsive: true,
    hoverOffset: 4,
    plugins: {
      tooltip: {
        enabled: true,
        boxWidth: 5,
        usePointStyle: true,
        pointStyle: 'rectRounded',
        bodyFont: {
          size: 10,
          family: 'montserrat',
        },
        boxPadding: 2,
      },
      title: {
        color: 'white',
        display: true,
        text: 'Current Holdings',
        font: {
          size: 15,
          family: 'montserrat',
        },
        padding: {
          top: 3,
          bottom: 1,
        },
      },
      legend: {
        display: true,
        position: 'right',
        onClick: null,
        labels: {
          color: 'white',
          boxWidth: 6,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          font: {
            size: 11,
            family: 'montserrat',
          },
        },
      },
    },
  };

  const sortedData = [];
  newData.forEach((e) => sortedData.push(e.am));

  const dataChart = useMemo(() => {
    const labels = projectNames;
    return {
      datasets: [
        {
          data: sortedData,
          backgroundColor: colorPalette,
          borderWidth: 1,
          borderColor: colorPalette,
        },
      ],
      labels,
    };
  }, [loaded]);

  return <Doughnut data={dataChart} options={options} />;
};

export default DonutChart;
