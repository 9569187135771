import React, { useState } from 'react';
import Photos from '../../views/modal/Photos';
import BtnSlider from './BtnSlider';
import './Carousel.css';
import { changeDomain } from '../../store/utils';

const Carousel = ({ dataSlider }) => {
  const [bigPhoto, setBigPhoto] = useState(false);
  const [slideIndex, setSlideIndex] = useState(1);

  const nextSlide = () => {
    if (slideIndex !== dataSlider?.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider?.length) {
      setSlideIndex(1);
    }
  };

  const prevSlide = () => {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider?.length);
    }
  };

  if (bigPhoto) {
    return (
      <Photos
        dataSlider={dataSlider}
        slideIndex={slideIndex}
        setSlideIndex={setSlideIndex}
        setBigPhoto={setBigPhoto}
      />
    );
  }

  return (
    <div className="container-slider">
      {dataSlider?.map((obj, index) => {
        return (
          <div
            key={obj._id}
            className={slideIndex === index + 1 ? 'slide active-anim' : 'slide'}
          >
            <img
              onClick={() => setBigPhoto(true)}
              alt="photos"
              className="object-cover h-[368px] w-[815px] rounded-t-2xl text-center lg:w-[650px] md:w-[550px] cursor-zoom-in"
              src={obj ? changeDomain(obj?.url) : null}
            />

            {dataSlider?.length <= 1 ? null : (
              <>
                <BtnSlider moveSlide={nextSlide} direction={'next'} />
                <BtnSlider moveSlide={prevSlide} direction={'prev'} />
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Carousel;
