import React from "react";

import logo from "../../images/index/logo.svg";

const ErrorPage = () => {
  return (
    <div className="mb-20 mr-6 flex flex-col items-center justify-center h-full">
      <img alt="logo" src={logo} className="w-[300px]" />
      <span className="text-gris text-4xl mt-12 font-semibold font-montserrat">
        {" "}
        404
      </span>
      <span className=" text-white text-xl mt-4 font-montserrat">
        PAGE NOT FOUND
      </span>
    </div>
  );
};

export default ErrorPage;
