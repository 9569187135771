import React from 'react';

import 'dayjs/locale/es';
import dayjs from 'dayjs';

import BarChart from './bar/BarChart';

export default function StChartQuarter({ data, loaded }) {
  const getProjectsByType = (type, dataProject) => {
    let project = [];
    project = dataProject?.filter(
      (investment) => investment?.project_type === type
    );
    return project;
  };

  const parseInvestments = (investments) => {
    let parsed = [];
    investments.forEach((inv) => {
      parsed.push(inv);
      return parsed.sort(
        (a, b) => new Date(b.investment_date) - new Date(a.investment_date)
      );
    });
    return parsed;
  };

  var today = new Date();

  // Estructura de quarters
  const dataQuarters = {
    decQuarter: {
      quarter: 4,
      month: 11,
      lastday: new Date(today.getFullYear(), 12, 0).getDate(),
    },
    MarchQuarter: {
      quarter: 1,
      month: 2,
      lastday: new Date(today.getFullYear(), 3, 0).getDate(),
    },
    JuneQuarter: {
      quarter: 2,
      month: 5,
      lastday: new Date(today.getFullYear(), 6, 0).getDate(),
    },
    SepQuarter: {
      quarter: 3,
      month: 8,
      lastday: new Date(today.getFullYear(), 9, 0).getDate(),
    },
  };

  const findQuarter = (d) => {
    if (d.quarterNumber === 4) {
      return {
        quarter: dataQuarters.SepQuarter,
        year: d.year,
      };
    }
    if (d.quarterNumber === 1) {
      return {
        quarter: dataQuarters.decQuarter,
        year: d.year,
      };
    }
    if (d.quarterNumber === 2) {
      return {
        quarter: dataQuarters.MarchQuarter,
        year: d.year,
      };
    }
    if (d.quarterNumber === 3) {
      return {
        quarter: dataQuarters.JuneQuarter,
        year: d.year,
      };
    }
  };

  const parsed = parseInvestments(data);

  // Obtiene el quarter actual
  const getQuarter = (d) => {
    return {
      quarterNumber: Math.floor((d.getMonth() + 3) / 3),
      year: d.getFullYear(),
    };
  };

  // fecha de cierre del ultimo quarter completo, no en el que estoy actualmente
  // si estoy en el 1er quarter, el año de fecha de cierre deberia ser el anterior, por eso la resta
  const closingDateCompletedQuarter = new Date(
    getQuarter(today).quarterNumber === 1
      ? findQuarter(getQuarter(today)).year - 1
      : findQuarter(getQuarter(today)).year,
    findQuarter(getQuarter(today)).quarter.month,
    findQuarter(getQuarter(today)).quarter.lastday
  );

  const filterQuarter = (projects, q) => {
    let finalizedInv = 0;
    let finalizedInv2 = 0;
    let finalizedInv3 = 0;
    let finalizedInv4 = 0;
    const newQuarter = dayjs(q)
      .set('month', q.getMonth() - 3)
      .endOf('month');
    const newQuarter2 = dayjs(q)
      .set('month', q.getMonth() - 6)
      .endOf('month');
    const newQuarter3 = dayjs(q)
      .set('month', q.getMonth() - 9)
      .endOf('month');

    let first = {
      amount: 0,
      month: new Date(q),
      finalized: finalizedInv4,
    };
    let second = {
      amount: 0,
      month: newQuarter.$d,
      finalized: finalizedInv3,
    };
    let third = {
      amount: 0,
      month: newQuarter2.$d,
      finalized: finalizedInv2,
    };
    let fourth = {
      amount: 0,
      month: newQuarter3.$d,
      finalized: finalizedInv,
    };

    projects.forEach((p) => {
      if (new Date(p.project_maturity) <= newQuarter3.$d) {
        fourth.finalized = finalizedInv += p.invested_capital;
      }

      if (
        new Date(p.project_maturity) <= newQuarter2.$d &&
        new Date(p.project_maturity) >= newQuarter3.$d
      ) {
        third.finalized = finalizedInv2 += p.invested_capital;
      }

      if (
        new Date(p.project_maturity) <= newQuarter.$d &&
        new Date(p.project_maturity) >= newQuarter2.$d
      ) {
        second.finalized = finalizedInv3 += p.invested_capital;
      }

      if (
        new Date(p.project_maturity) <= closingDateCompletedQuarter &&
        new Date(p.project_maturity) >= newQuarter.$d
      ) {
        first.finalized = finalizedInv4 += p.invested_capital;
      }

      if (
        new Date(p.investment_date) >= newQuarter.$d &&
        new Date(p.investment_date) <= closingDateCompletedQuarter
      ) {
        first.amount = first.amount += parseInt(p.invested_capital);
      }

      if (
        new Date(p.investment_date) >= newQuarter2.$d &&
        new Date(p.investment_date) <= newQuarter.$d
      ) {
        second.amount = second.amount += parseInt(p.invested_capital);
      }
      if (
        new Date(p.investment_date) >= newQuarter3.$d &&
        new Date(p.investment_date) <= newQuarter2.$d
      ) {
        third.amount = third.amount += parseInt(p.invested_capital);
      }
      if (new Date(p.investment_date) <= newQuarter3.$d) {
        fourth.amount = fourth.amount += parseInt(p.invested_capital);
      }
    });

    return {
      first,
      second,
      third,
      fourth,
    };
  };

  let portfolioLoans = getProjectsByType('PORTFOLIO LOAN', parsed);
  let equityLoans = getProjectsByType('EQUITY INVESTMENT', parsed);

  let newMonths = [];

  Object.values(
    filterQuarter(portfolioLoans, closingDateCompletedQuarter)
  ).forEach((e) => {
    newMonths.push(
      new Date(e.month).toLocaleString('en-us', {
        month: 'short',
        year: 'numeric',
      })
    );
  });

  const parsedPortfolios = Object.values(
    filterQuarter(portfolioLoans, closingDateCompletedQuarter)
  );
  const parsedEquitys = Object.values(
    filterQuarter(equityLoans, closingDateCompletedQuarter)
  );

  // logic should be that the fourth quarter, should be equal to the last completed quarter (???)
  let monthsToCompare;
  switch (getQuarter(today).quarterNumber) {
    case 1:
      monthsToCompare = [11, 2, 5, 8];
      break;
    case 2:
      monthsToCompare = [2, 5, 8, 11];
      break;
    case 3:
      monthsToCompare = [5, 8, 11, 2];
      break;
    case 4:
      monthsToCompare = [8, 11, 2, 5];
      break;
    default:
      break;
  }

  const totalAmountPort = (port) => {
    let first = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let second = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let third = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let fourth = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };

    port.forEach((p) => {
      if (new Date(p.month).getMonth() === monthsToCompare[0]) {
        fourth.totalAmount += p.amount;
        fourth.month = p.month;
        fourth.finalized += p.finalized;
      }
      if (new Date(p.month).getMonth() === monthsToCompare[1]) {
        first.totalAmount += p.amount;
        first.month = p.month;
        first.finalized += p.finalized;
      }
      if (new Date(p.month).getMonth() === monthsToCompare[2]) {
        second.totalAmount += p.amount;
        second.month = p.month;
        second.finalized += p.finalized;
      }
      if (new Date(p.month).getMonth() === monthsToCompare[3]) {
        third.totalAmount += p.amount;
        third.month = p.month;
        third.finalized += p.finalized;
      }
    });

    return { first, second, third, fourth };
  };

  const totalAmountEq = (eq) => {
    let first = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let second = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let third = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    let fourth = {
      totalAmount: 0,
      month: new Date(),
      finalized: 0,
    };
    eq.forEach((e) => {
      if (new Date(e.month).getMonth() === monthsToCompare[0]) {
        fourth.totalAmount += e.amount;
        fourth.month = e.month;
        fourth.finalized += e.finalized;
      }
      if (new Date(e.month).getMonth() === monthsToCompare[1]) {
        first.totalAmount += e.amount;
        first.month = e.month;
        first.finalized += e.finalized;
      }
      if (new Date(e.month).getMonth() === monthsToCompare[2]) {
        second.totalAmount += e.amount;
        second.month = e.month;
        second.finalized += e.finalized;
      }
      if (new Date(e.month).getMonth() === monthsToCompare[3]) {
        third.totalAmount += e.amount;
        third.month = e.month;
        third.finalized += e.finalized;
      }
    });
    return { first, second, third, fourth };
  };

  const finalizedEq = Object.values(totalAmountEq(parsedEquitys));
  const finalizedPort = Object.values(totalAmountPort(parsedPortfolios));

  const barData2 = (port, eq) => {
    let res = {
      0: [],
      1: [],
    };
    let amount = 0;
    port.forEach((p) => {
      amount = p.totalAmount - p.finalized;
      res[0].push(amount);
    });
    eq.forEach((e) => {
      amount = e.totalAmount - e.finalized;
      res[1].push(amount);
    });

    res[0][1] = res[0][1] + res[0][0];
    res[0][2] = res[0][2] + res[0][1];
    res[0][3] = res[0][3] + res[0][2];

    res[1][1] = res[1][1] + res[1][0];
    res[1][2] = res[1][2] + res[1][1];
    res[1][3] = res[1][3] + res[1][2];

    return res;
  };

  return (
    <div className="flex flex-col">
      <div className="ml-2 w-[380px] lg:w-[300px] lg:ml-4 ">
        <BarChart
          data1={barData2(finalizedPort, finalizedEq)[0]}
          data2={barData2(finalizedPort, finalizedEq)[1]}
          loaded={loaded}
          monthList={newMonths.reverse()}
        />
      </div>
    </div>
  );
}
