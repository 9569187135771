import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { serviceBASE } from '../../../config/axios';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';

import back from '../../../images/index/back-button.svg';
import Spinner from '../../../components/spinner/Spinner';
import Detail from './blockviews/Detail';
import InvestDoc from './blockviews/InvestDoc';
import Updates from './blockviews/Updates';
import InvestorDoc from './blockviews/InvestorDoc';

const InfoHolding = () => {
  const { currentId } = useParams();
  const [data, setData] = useState([]);
  const [sp, setSp] = useState(true);

  let location = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    const consulta = async () => {
      const response = await serviceBASE.get(`/users/investments/${currentId}`);
      setData(response.data);
      setSp(false);
    };
    consulta();
  }, [currentId]);

  return (
    <>
      {sp && <Spinner />}
      <div className="flex flex-col min-h-[90vh] font-montserrat items-center text-white">
        <div className="text-white mt-4 w-[870px] lg:w-[400px] md:w-[400px] text-3xl">
          <img
            alt="back"
            src={back}
            className="cursor-pointer"
            onClick={() => {
              if (location.state !== null) {
                window.location.reload(navigate('/notifications'));
              } else navigate(-1);
            }}
          />
        </div>
        <h3 className="text-white text-center mb-6">{data?.project_name}</h3>
        <div className="flex lg:flex-col">
          <div className="flex flex-col w-[50%] mb-8">
            <div className="flex flex-col w-[420px] mr-4 rounded-2xl bg-bgprimary_ob justify-between">
              <span className="text-center border-photo_ob border-b-[2px] w-[90%] pb-2 mb-0 mt-2 m-auto">
                Detail | CurrentValue
              </span>
              <Detail data={data} />
            </div>
            <div className="flex flex-col w-[420px] grow mr-4 rounded-2xl mt-4 bg-bgprimary_ob">
              <span className="text-center border-photo_ob border-b-[2px] w-[90%] pb-2 mb-0 mt-2 m-auto">
                Investment Documentation
              </span>
              <InvestDoc data={data} />
            </div>
          </div>
          <div className="flex flex-col w-[50%] mb-8">
            <div className="flex flex-col w-[420px] rounded-2xl bg-bgprimary_ob items-center">
              <span className="text-center border-photo_ob border-b-[2px] w-[90%] pb-2 mb-0 mt-2 m-auto">
                Updates
              </span>
              <Updates data={data} />
            </div>
            <div className="flex flex-col w-[420px] grow mr-4 rounded-2xl mt-4 bg-bgprimary_ob">
              <span className="text-center border-photo_ob border-b-[2px] w-[90%] pb-2 mb-0 mt-2 m-auto">
                Investor Documentation
              </span>
              <InvestorDoc data={data} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoHolding;
