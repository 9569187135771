import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { toggleLoading, state } from '../../../../store/store';

import Error from '../../../../components/Messages/Error';
import TextInputAc from '../../../modal/TextInputAc';
import TextInputPw from '../../../modal/TextInputPw';
import Spinner from '../../../../components/spinner/Spinner';
import AuthContext from '../../../../config/auth/authContext';
import back from '../../../../images/index/back-button.svg';

const RegisterForm = ({ setIsRegister, newUser, setNewUser }) => {
  const { errMsg, setErrMsg } = useContext(AuthContext);

  // toggleLoading();
  // registerUser({ name, email, password });

  let navigate = useNavigate();

  const { email, password, confirmPw } = newUser;

  const onChangeForm = (e) => {
    setNewUser({
      ...newUser,
      [e.target.name]: e.target.value,
    });
  };

  const validateEmail = (email) => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return re.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if ((email || password || confirmPw) === '') {
      return setErrMsg('Please, complete all the fields');
    } else if (!validateEmail(email)) {
      return setErrMsg('Please enter a valid email');
    } else if (password !== confirmPw) {
      return setErrMsg('Passwords must be the same');
    }
    setIsRegister(true);
    setErrMsg('');
  };

  return (
    <>
      {state.isLoading && <Spinner />}
      <form onSubmit={handleSubmit}>
        <img
          alt="back_button"
          src={back}
          className="text-xl self-start cursor-pointer"
          onClick={() => {
            navigate('/signup');
            setErrMsg('');
          }}
        />

        <div className="flex flex-col mt-4">
          <TextInputAc
            name={'email'}
            value={email}
            type={'email'}
            onChangeForm={onChangeForm}
          />
          <label className="text-xs my-1" htmlFor="email">
            EMAIL
          </label>
        </div>

        <div className="flex border-photo_ob border-b-[2px] mt-4">
          <TextInputPw
            name={'password'}
            onChangeForm={onChangeForm}
            value={password}
          />
        </div>
        <label className="text-xs mt-2" htmlFor="pw">
          PASSWORD
        </label>
        <div className="flex border-photo_ob border-b-[2px] mt-4">
          <TextInputPw
            name={'confirmPw'}
            onChangeForm={onChangeForm}
            value={confirmPw}
          />
        </div>
        <label className="text-xs mt-2" htmlFor="confpw">
          CONFIRM YOUR PASSWORD
        </label>
        {errMsg ? <Error message={errMsg} /> : null}
        <div className="flex flex-col items-center">
          <button
            type="submit"
            className="bg-bggray_ob w-[25%] py-1 rounded-2xl my-8 hover:bg-gray-500 hover:drop-shadow-xl"
          >
            Next
          </button>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
