import React, { useState } from "react";
import "./style/InputStyle.css";
import eye_off from "../../images/modal/eye_off.svg";
import eye_on from "../../images/modal/eye_on.svg";

const TextInputPw = ({ type, name, img, onChangeForm, value }) => {
  const [isShow, setIsShow] = useState(false);

  const handleClickeye = () => {
    if (isShow) setIsShow(false);
    else setIsShow(true);
  };

  return (
    <>
      <input
        type={isShow ? "text" : "password"}
        name={name}
        className="outline-[0px] w-full mr-2 bg-transparent placeholder:text-base placeholder:font-light placeholder:text-white"
        onChange={onChangeForm}
        value={value}
      />
      <button type="button" onClick={handleClickeye}>
        <img alt="eye" src={!isShow ? eye_off : eye_on} />
      </button>
    </>
  );
};

export default TextInputPw;
